import React from "react";
import Grid from '@mui/material/Grid';
import * as custom from "./getCustomTypography";
import * as portalShared from "../shared/metadataConstantsAndUtilities"


export function getCategoryTitleBar(qnr, categoryTitleObj, itemObj, convertedVariant, uploadedImageStyles, objId) {

    let htmlObj = <div></div>;

    //let categoryTemplate = ddo.getCategory(qnr, itemObj.id);

    let bColor = portalShared.COLOR_PRIMARY_BLUE;
    let bOpacity = "100%";
    let bHeight = portalShared.CATEGORY_TITLE_BAR_DEFAULT_HEIGHT; // Text size can override height

    //let leftOnly = categoryTitleObj.useCustomLeft && (!categoryTitleObj.useCustomCenter && !categoryTitleObj.useCustomRight);

    if (categoryTitleObj.categoryCustomObj.color.colorType !== "default") {
        bColor = (categoryTitleObj.categoryCustomObj.color.colorType === "select") ? categoryTitleObj.categoryCustomObj.color.color : categoryTitleObj.categoryCustomObj.color.colorEntry;
        bOpacity = categoryTitleObj.categoryCustomObj.color.opacity;
        bHeight = categoryTitleObj.categoryCustomObj.height;
    }


    // Custom category header !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    if (!categoryTitleObj.useCustomLeft && !categoryTitleObj.useCustomCenter && !categoryTitleObj.useCustomRight) {

        // Title Bar NO Text, Could be used as a simple divider
        htmlObj = <Grid key={"CatTitle-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px"}}>
            <div style={{width: '100%', display: 'flex'}}></div>
        </Grid>;
    }
    else if (categoryTitleObj.useCustomLeft && categoryTitleObj.useCustomCenter && categoryTitleObj.useCustomRight) {

        let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");
        let CustomCenter = custom.getCustomTypography(categoryTitleObj.titleCenterCustomObj, "center");
        let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

        htmlObj = <Grid key={"CatTitleBar1-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
            <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                <div style={{textAlign: 'left', width: '35%'}}>
                    {categoryTitleObj.useCustomLeft ? <CustomLeft>{categoryTitleObj.titleLeft}</CustomLeft> : ""}
                </div>
                <div style={{textAlign: 'center', width: '30%'}}>
                    {categoryTitleObj.useCustomCenter ? <CustomCenter>{categoryTitleObj.titleCenter}</CustomCenter> : ""}
                </div>
                <div style={{textAlign: 'right', width: '35%'}}>
                    {categoryTitleObj.useCustomRight ? <CustomRight>{categoryTitleObj.titleRight}</CustomRight> : ""}
                </div>
            </div>
        </Grid>;

    }
    else {

        // Used to help spacing on long titles where there is only one being used
        let leftTitleOnly    = (categoryTitleObj.titleLeft !== "") && (categoryTitleObj.titleCenter === "") && (categoryTitleObj.titleRight === "");
        let centerTitleOnly  = (categoryTitleObj.titleLeft === "") && (categoryTitleObj.titleCenter !== "") && (categoryTitleObj.titleRight === "");
        let rightTitleOnly   = (categoryTitleObj.titleLeft === "") && (categoryTitleObj.titleCenter === "") && (categoryTitleObj.titleRight !== "");
        let leftAndRightOnly = (categoryTitleObj.titleLeft !== "") && (categoryTitleObj.titleCenter === "") && (categoryTitleObj.titleRight !== "");

        if (leftTitleOnly) {

            let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");

            htmlObj =
                <Grid key={"CatTitleBar2-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'left', width: '100%', textAlign: 'left'}}>
                            <CustomLeft>{categoryTitleObj.titleLeft}</CustomLeft>
                        </div>
                    </div>
                </Grid>;
        }
        else if (centerTitleOnly) {

            let CustomCenter = custom.getCustomTypography(categoryTitleObj.titleCenterCustomObj, "center");

            htmlObj =
                <Grid key={"CatTitleBar3-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'right', width: '100%', textAlign: 'center'}}>
                            <CustomCenter>{categoryTitleObj.titleCenter}</CustomCenter>
                        </div>
                    </div>
                </Grid>;
        }
        else if (rightTitleOnly) {

            let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

            htmlObj =
                <Grid key={"CatTitleBar4-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'right', width: '100%', textAlign: 'right'}}>
                            <CustomRight>{categoryTitleObj.titleRight}</CustomRight>
                        </div>
                    </div>
                </Grid>;
        }
        else if (leftAndRightOnly) {

            let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");
            let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

            htmlObj =
                <Grid key={"CatTitleBar5-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'left', width: '50%'}}>
                            <CustomLeft>{categoryTitleObj.titleLeft}</CustomLeft>
                        </div>
                        <div style={{float: 'right', width: '50%', textAlign: 'right'}}>
                            <CustomRight>{categoryTitleObj.titleRight}</CustomRight>
                        </div>
                    </div>
                </Grid>;
        }
        else {

            let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");
            let CustomCenter = custom.getCustomTypography(categoryTitleObj.titleCenterCustomObj, "center");
            let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

            htmlObj =
                <Grid key={"CatTitleBar6-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'left', width: '35%'}}>
                            <CustomLeft>{categoryTitleObj.titleLeft}</CustomLeft>
                        </div>
                        <div style={{float: 'center', width: '30%', textAlign: 'center'}}>
                            <CustomCenter>{categoryTitleObj.titleCenter}</CustomCenter>
                        </div>
                        <div style={{float: 'right', width: '35%', textAlign: 'right'}}>
                            <CustomRight>{categoryTitleObj.titleRight}</CustomRight>
                        </div>
                    </div>
                </Grid>;
        }
    }

    return htmlObj;
}
