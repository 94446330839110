import * as TEMPLATE from "../../constants/templateConstants";
import * as questionTypes from "../../constants/questionTypeConstants";

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DEFAULT COLORS
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// Survey Default Colors -------=---------------------------------------------------------------------------------------
// export const DEFAULT_WHITE = "white";
// export const DEFAULT_BLACK = "black";
// export const DEFAULT_GRAY = "gray";
// export const DEFAULT_UNSELECTED_COLOR = DEFAULT_GRAY;
// export const DEFAULT_SELECTED_COLOR = "#2497F3"; // dodger blue

// Survey Default Colors -------=---------------------------------------------------------------------------------------
export const DEFAULT_WHITE = "white";
export const DEFAULT_BLACK = "black";
export const DEFAULT_GRAY = "gray";
export const DEFAULT_UNSELECTED_COLOR = DEFAULT_GRAY;
export const DEFAULT_SELECTED_COLOR = "#2497F3"; // dodger blue

export const COLOR_PRIMARY_BLUE = "#01579b";
export const COLOR_DODGER_BLUE = "#2497F3";

export const DEFAULT_TEXT_UNSELECTED_COLOR = "lightgray";
export const DEFAULT_TEXT_HOVER_COLOR = "lightgray";
export const DEFAULT_TEXT_FOCUSED_COLOR = "gray";
export const DEFAULT_TEXT_SELECTED_COLOR = "black";


export const DEFAULT_TEXT_FIELD_LABEL_COLOR = DEFAULT_GRAY;
export const DEFAULT_TEXT_FIELD_LABEL_COLOR_HOVERED = DEFAULT_GRAY;
export const DEFAULT_TEXT_FIELD_LABEL_COLOR_FOCUSED = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR = DEFAULT_GRAY;
export const DEFAULT_TEXT_FIELD_PLACEHOLDER_OPACITY = 1;
export const DEFAULT_TEXT_FIELD_TEXT_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_FIELD_TEXT_COLOR_HOVERED = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_FIELD_TEXT_COLOR_FOCUSED = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_FIELD_BACKGROUND_COLOR = DEFAULT_WHITE;
export const DEFAULT_TEXT_FIELD_BACKGROUND_COLOR_HOVERED = DEFAULT_WHITE;
export const DEFAULT_TEXT_FIELD_BACKGROUND_COLOR_FOCUSED = DEFAULT_WHITE;

export const DEFAULT_TEXT_FIELD_FILLED_BACKGROUND_COLOR = "#F5F5F5";
export const DEFAULT_TEXT_FIELD_FILLED_BACKGROUND_COLOR_HOVERED = "#E8E8E8";
export const DEFAULT_TEXT_FIELD_FILLED_BACKGROUND_COLOR_FOCUSED = "#F5F5F5";
export const DEFAULT_TEXT_FIELD_BORDER_COLOR = DEFAULT_GRAY;
export const DEFAULT_TEXT_FIELD_TEXT_BORDER_COLOR_HOVERED = DEFAULT_BLACK;
export const DEFAULT_TEXT_FIELD_TEXT_BORDER_COLOR_FOCUSED = DEFAULT_SELECTED_COLOR;




// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// CONSTANTS
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const NON_BREAKING_SPACE = "\u00A0";
export const NON_BREAKING_SPACE2 = "\u00A0\u00A0";




export const OBJ_TYPE_COLOR = "color";
export const OBJ_TYPE_COLOR_SHORT = "cs";
export const OBJ_TYPE_ICON = "icon";
export const OBJ_TYPE_TEXT = "text";
export const OBJ_TYPE_BUTTON = "button";
export const OBJ_TYPE_CUSTOM_QNR_APP_HEADER = "custom-qnr-app-header";
export const OBJ_TYPE_CUSTOM_QNR_START_PAGE = "custom-qnr-start-page";
export const OBJ_TYPE_CUSTOM_QNR_MAIN_PAGES = "custom-qnr-main-pages";
export const OBJ_TYPE_CUSTOM_QNR_CLOSE_PAGE = "custom-qnr-close-page";
export const OBJ_TYPE_CUSTOM_QNR_SYSTEM_BUTTONS = "custom-qnr-system-buttons";
export const OBJ_TYPE_CUSTOM_QNR_ALERT_TEXT = "custom-qnr-alert-text";
export const OBJ_TYPE_CUSTOM_CAT = "custom-cat";
export const OBJ_TYPE_CUSTOM_GRP = "custom-grp"; // NO CUSTOM GROUP
export const OBJ_TYPE_CUSTOM_LIKERT = "custom-likert"; // Used on GROUP and QUESTION templates
export const OBJ_TYPE_CUSTOM_QUE_RADIO = "custom-que-radio";
export const OBJ_TYPE_CUSTOM_QUE_CHECKBOX = "custom-que-checkbox";
export const OBJ_TYPE_CUSTOM_QUE_TEXT = "custom-que-text";
export const OBJ_TYPE_CUSTOM_QUE_ADDRESS_TEXT = "custom-que-address-text";
export const OBJ_TYPE_CUSTOM_QUE_ADDRESS = "custom-que-address";
export const OBJ_TYPE_CUSTOM_QUE_DATE_RANGE = "custom-que-date-range";


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// CLONE Object
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function cloneObj(obj) {
    return JSON.parse(JSON.stringify(obj));
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// COPY MODIFIED OBJECT INTO DEFAULT OBJECT (RECURSION !!!!!)
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function setObj(defaultObj, currentObj) {

    for (var key in defaultObj) {
        // NOTE: NULL typeof is "object"
        if ((typeof defaultObj[key] === "object") && (defaultObj[key] !== null)) {
            if (currentObj.hasOwnProperty(key)) {
                setObj(defaultObj[key], currentObj[key]); // RECURSION !!!!!
            }
        }
        else if (currentObj.hasOwnProperty(key)) {
            defaultObj[key] = currentObj[key];
        }
    }
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// GET Metadata Object/Value
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function getMetadataObj(metadata, tag) {
    let metadataObj = null;
    if (metadata.hasOwnProperty(tag)) {
        metadataObj = JSON.parse(cloneObj(metadata[tag])); // Objects are stored stringified
    }
    return metadataObj;
}

export function getMetadataValue(metadata, tag) {
    let metadataValue = "";
    if (metadata.hasOwnProperty(tag)) {
        metadataValue = metadata[tag];
    }
    return metadataValue;
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// COLOR Object
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const customColorObject = {
    version: "1",
    objType: OBJ_TYPE_COLOR,
    colorType: "default", // default, select, hex or rbg
    colorIndex: null,
    colorEntry: "black",
    color: "black",
    opacityEntry: "100",
    opacity: "100%"
};

// Having issues with DDLB exceeding size so created a SHORT color without opacity
export const customColorObjectShort = {
    v: "1",
    t: OBJ_TYPE_COLOR_SHORT,
    colorType: "default", // default, select, hex or rbg
    colorIndex: null,
    colorEntry: "black",
    color: "black",
};

export function setCustomColorObject(currentObj) {

    let colorObj = cloneObj(customColorObject);

    setObj(colorObj, currentObj);

    return colorObj;
}

export function setCustomColorObjectToFrom(toObj, fromObj) {
    let tempFromObj = cloneObj(toObj);

    setObj(toObj, tempFromObj);

    return toObj;
}

// setColorObj = (selectedMode, savedObj) => {
//
//     let isDefault = (selectedMode === "default");
//
//     let colorObj = {};
//
//     colorObj.backgroundColor = isDefault ?  "" : savedObj.backgroundColor;
//     colorObj.colorType = isDefault ?  "default" : savedObj.colorType;
//     colorObj.colorIndex = isDefault ?  null : savedObj.colorIndex;
//     colorObj.colorEntry = isDefault ?  "black" : savedObj.colorEntry;
//     colorObj.color = isDefault ?  "black" : savedObj.color;
//     colorObj.opacity = isDefault ? "100%" : savedObj.opacity;
//     colorObj.opacityEntry = isDefault ? "100" : savedObj.opacityEntry;
//
//     return colorObj;
// }

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ICON Object
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const DISPLAY_DEF_DESCRIPTION_ICON_NONE  = "display-def-description-icon-none";      // (All Templates)
export const DISPLAY_DEF_DESCRIPTION_ICON_SIZE  = "display-def-description-icon-size";      // (All Templates)
export const DISPLAY_DEF_DESCRIPTION_ICON_COLOR  = "display-def-description-icon-color";    // (All Templates)
export const DISPLAY_DEF_DESCRIPTION_ICON_PADDING  = "display-def-description-icon-padding";    // (All Templates)


export const DISPLAY_DEF_SCRIPT_ICON_NONE  = "display-def-script-icon-none";      // (All Templates)
export const DISPLAY_DEF_SCRIPT_ICON_SIZE  = "display-def-script-icon-size";      // (All Templates)
export const DISPLAY_DEF_SCRIPT_ICON_COLOR  = "display-def-script-icon-color";    // (All Templates)
export const DISPLAY_DEF_SCRIPT_ICON_PADDING  = "display-def-script-icon-padding";    // (All Templates)

//export const ICON_POSITION  = "icon-position";
export const ICON_UNSELECTED_COLOR  = "icon-unselected-color";
export const ICON_SELECTED_COLOR  = "icon-selected-color";

//export const OVERRIDE_ICON_POSITION  = "display-def-icon-position";

export const OVERRIDE_ICON_UNSELECTED_COLOR  = "display-def-icon-unselected-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_UNSELECTED_OPACITY  = "display-def-icon-unselected-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_UNSELECTED_BACKGROUND_COLOR  = "display-def-icon-unselected-background-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_UNSELECTED_BACKGROUND_OPACITY  = "display-def-icon-unselected-background-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_UNSELECTED_TEXT_COLOR  = "display-def-icon-unselected-text-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_UNSELECTED_TEXT_OPACITY  = "display-def-icon-unselected-text-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_SELECTED_BORDER_COLOR  = "display-def-icon-selected-border-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_SELECTED_BORDER_OPACITY  = "display-def-icon-selected-border-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_SELECTED_COLOR  = "display-def-icon-selected-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_SELECTED_OPACITY  = "display-def-icon-selected-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_SELECTED_TEXT_COLOR  = "display-def-icon-selected-text-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_SELECTED_TEXT_OPACITY  = "display-def-icon-selected-text-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_TEXT_FONT  = "display-def-icon-text-font"; // (Group/Question) level icon font override

export const OVERRIDE_ICON_SQUARE_BORDER_WIDTH  = "display-def-icon-square-border-width"; // (Group/Question) level icon font override


export const customIconObject = {
    version: "1",
    objType: OBJ_TYPE_ICON,
    hidden: true, // We should just use icon === none and remove this field
    icon: "", // none or select icon info, clear/solid or comment clear/solid
    size: "24pt",
    colorType: "default", // default, select, hex or rbg
    colorIndex: null,
    colorEntry: "black",
    color: "black",
    opacityEntry: "100",
    opacity: "100%"
};

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// TEXT (NAMES, DESCRIPTIONS, SCRIPTS, ANSWER TEXT, ANSWER ENTRIES, TITLES...)
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const DISPLAY_DEF_NAME  = "display-def-name";                    // (All Templates)
export const CUSTOM_DDO_NAME = "custom-ddo-name"; // Used by Questionnaire, Category, Group and Question
export const DISPLAY_DEF_DESCRIPTION  = "display-def-description";      // (All Templates)
export const CUSTOM_DDO_DESC = "custom-ddo-desc"; // Used by Questionnaire, Category, Group and Question
export const CUSTOM_DDO_LABEL  = "custom-ddo-label"; // (Question)

// Custom Text
export const customTextObject = {
    version: "1",
    objType: OBJ_TYPE_TEXT,
    title: "",
    format: "default", // or custom
    hide: false,
    font: "roboto",
    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: "normal",
    fontSizing: "points",
    fontSizeEntry: "",
    fontSize: "",
    variant: "",
    fontStyle: "normal",
    textAlign: "left",   // left center right
    iconObj: null, // Only Description Entries have icons
    colorObj: {...customColorObject}
}

export function getCustomTextObj(templateType, textEntryType) {
    let textObj = cloneObj(customTextObject);

    let textDefaultsObj = getTextDefaults(templateType, textEntryType);

    // Font default sizes are set depending on the type ie.. Template Type and Text Entry Type
    textObj.variant = textDefaultsObj.variant;
    textObj.fontSizeEntry = textDefaultsObj.fontSizeEntry;
    textObj.fontSize = textDefaultsObj.fontSize;

    return textObj;
}

export function setCustomTextObject(currentObj) {

    let textObj = cloneObj(customTextObject); // Clone

    setObj(textObj, currentObj);

    return textObj;
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// QUESTIONNAIRE
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const PORTAL_TAB_TITLE = "custom-ui-portal-tab-title"; // Used to set the web browser tab name
export const KEY_METRIC_CATEGORY_QUESTIONNAIRE = "reporting-category-questionnaire"; // Changed to Key Metric Category in UI

export const CUSTOM_DDO_DEFAULTS  = "custom-ddo-defaults"; // (Questionnaire) custom overrides for all default text
export const CUSTOM_DDO_BUTTONS_RAISED  = "custom-ddo-buttons-raised"; // (Questionnaire) custom overrides for all raised buttons
export const CUSTOM_DDO_BUTTONS_PLAIN  = "custom-ddo-buttons-plain"; // (Questionnaire) custom overrides for all plain buttons

//export const CUSTOM_DDO_QNR_FORMAT_ALERTS  = "custom-ddo-questionnaire-format-alerts"; // (Questionnaire)



export const NO_SUBMIT  = "display-def-no-submit";  // (Questionnaire) Used to suppress submit button on real-time template mode (NOT FOR PUBLIC USE)

// Survey Presentation Overrides ---------------------------------------------------------------------------------------

//export const FORMAT_IS_METADATA  = "display-def-value-is-format";  // (Questionnaire) Actual metadata value is the ddo (TBD)

export const HIDE_READONLY_HEADER  = "display-def-hide-readonly-header";
export const SHOW_READONLY_HEADER  = "display-def-show-readonly-header";
export const FILTER_READONLY_HEADER  = "display-def-filter-readonly-header";  // This is the default left it here in case they change their minds

export const ALIGN_ALL_ANSWERS_RIGHT  = "align-all-answers-right";  // (Questionnaire) Align all answers right

// ---------------------------------------------------------------------------------------------------------------------
// Questionnaire has 4 tab objects HEADER, START PAGE, MAIN PAGES and CLOSE PAGE
// CLOSE PAGE is included by default in all questionnaires, but can be removed by setting close page DDLB to NO



// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// HEADER SECTION (SURVEY APPBAR)
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_APP_HEADER_OBJ  = "custom-obj-qnr-app-header";
export const CUSTOM_QNR_APP_HEADER_OBJ_LEGACY  = "custom-ddo-questionnaire-format-app-header";

// Application Title Bar -----------------------------------------------------------------------------------------------
// export const APPBAR  = "display-def-appbar";
// export const HEADER_APPBAR_TITLE_LEFT  = "display-def-appbar-title-left";  //(Questionnaire) scr= ot Text or #pageNum
// export const HEADER_APPBAR_TITLE_CENTER  = "display-def-appbar-title-center"; //(Questionnaire) scr= ot Text or #pageNum
// export const HEADER_APPBAR_TITLE_RIGHT  = "display-def-appbar-title-right";  //(Questionnaire) scr= ot Text or #pageNum

export const HEADER_APPBAR_IMAGE_LEFT = "image-app-title-bar-left"; // (Questionnaire)
export const HEADER_APPBAR_IMAGE_CENTER = "image-app-title-bar-center"; // (Questionnaire)
export const HEADER_APPBAR_IMAGE_RIGHT = "image-app-title-bar-right"; // (Questionnaire)

export const customQuestionnaireAppHeaderObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QNR_APP_HEADER,
    //format: "custom",             // LEGACY, No longer used if this exists in metadata it must be custom
    colorObj: null,                 // APPBAR Color NOT used yet
    height: "",                     // APPBAR Height NOT used yet
    appHeaderLeftType: "none",      // none, text or image
    appHeaderLeftTextObj: null,     // If Text standard text object, if Image use separate metadata entry
    appHeaderCenterType: "none",    // none, text or image
    appHeaderCenterTextObj: null,   // If Text standard text object, if Image use separate metadata entry
    appHeaderRightType: "none",     // none, text or image
    appHeaderRightTextObj: null,    // If Text standard text object, if Image use separate metadata entry
}

export function getCustomQuestionnaireAppHeaderObj() {

    let customObj = cloneObj(customQuestionnaireAppHeaderObj);

    if (customObj.appHeaderLeftType === "text") {
        customObj.appHeaderLeftTextObj = cloneObj(customTextObject);
    } else {
        customObj.appHeaderLeftTextObj = null; // None and Image
    }

    if (customObj.appHeaderCenterType === "text") {
        customObj.appHeaderCenterTextObj.metadataValueObj = cloneObj(customTextObject);
    } else {
        customObj.appHeaderCenterTextObj = null; // None and Image
    }

    if (customObj.appHeaderRightType === "text") {
        customObj.appHeaderRightTextObj.metadataValueObj = cloneObj(customTextObject);
    } else {
        customObj.appHeaderRightTextObj = null; // None and Image
    }

    return customObj;
}

export function setCustomQuestionnaireAppHeaderObj(newObj) {
    let appHeaderObj = cloneObj(customQuestionnaireAppHeaderObj);

    setObj(appHeaderObj, newObj);

    return appHeaderObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// START PAGE SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_START_PAGE_OBJ  = "custom-obj-qnr-start-page";
export const QNR_START_PAGE_SCRIPT  = "user-script"; // (Questionnaire) // Allows TOKENS so must be stand-alone metadata entry
export const QNR_START_PAGE_SCRIPT_FORMAT_OBJ  = "user-script-format-obj"; // (Questionnaire)


export const customQuestionnaireStartPageObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QNR_START_PAGE,
    //format: "custom",                               // LEGACY, NOT USED ANYMORE
    hideName: false,                        // Hade questionnaire name from start page
    titleText: "",                          // NOT used for future expansion
    titleFormat: "default",                     // NOT used for future expansion
    titleTextCustomObj: null,                             // NOT used for future expansion
    scriptFormat: "default",                        // NOT used for future expansion
    scriptCustomObj: null,                          // NOT used for future expansion
    defaultText: "Begin Questionnaire?",
    defaultTextFormat: "default",
    defaultTextCustomObj: null
}

export function getCustomQuestionnaireStartPageObj() {
    return cloneObj(customQuestionnaireStartPageObj);
}

export function getCustomTextStartPageScript(templateType) {
    let customObj = cloneObj(customTextObject);

    let textDefault = getTextDefaults(TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.START_PAGE_SCRIPT);

    customObj.fontSizeEntry = textDefault.fontSizeEntry;
    customObj.fontSize = textDefault.fontSize;
    customObj.variant = textDefault.variant;

    return customObj;
}

export function setCustomQuestionnaireStartPageObj(newObj) {

    let startPageObj = cloneObj(customQuestionnaireStartPageObj);

    setObj(startPageObj, newObj);

    return startPageObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// MAIN PAGES SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_MAIN_PAGES_OBJ  = "custom-obj-qnr-main-pages";

export const INSTANT_MODE  = "INSTANT_MODE";
export const TEMPLATE_MODE  = "TEMPLATE_MODE";

export const SKIP_FIRST_QUESTION  = "SKIP_FIRST_QUESTION";
export const SHOW_FIRST_QUESTION  = "SHOW_FIRST_QUESTION";

export const EDITABLE_SURVEY  = "EDITABLE_SURVEY";
export const READONLY_SURVEY  = "READONLY_SURVEY";

export const READONLY_SURVEY_URL_SHOW_HEADER_NONE  = "READONLY_SURVEY_URL_SHOW_HEADER_NONE";
export const READONLY_SURVEY_URL_SHOW_HEADER_TRUE  = "READONLY_SURVEY_URL_SHOW_HEADER_TRUE";
export const READONLY_SURVEY_URL_SHOW_HEADER_FALSE  = "READONLY_SURVEY_URL_SHOW_HEADER_FALSE";
export const READONLY_SURVEY_URL_SHOW_HEADER_FILTERED  = "READONLY_SURVEY_URL_SHOW_HEADER_FILTERED";

export const SHOW_ANSWERED_FIRST_QUESTION  = "display-def-show-answered-first-question";  // (Questionnaire) Used to show the first question if being answered from an email and passed in


// Good article on question numbers: https://help.alchemer.com/help/question-numbering
export const SHOW_QUESTION_NUMBERS  = "display-def-show-question-numbers";  // (Questionnaire) . or ) default just the number
export const SUBNUMBER_LIKERT_QUESTIONS  = "display-def-subnumber-likert-questions";  // (Questionnaire) a or a) or (a) or Xa or X.#   ...by default none
export const SUBNUMBER_SHOWGROUP_QUESTIONS  = "display-def-subnumber-showgroup-questions";  // (Questionnaire) a or a) or (a) or Xa or X.#  ...by default none
export const RESTART_QUESTION_NUMBERS_EACH_PAGE  = "display-def-restart-numbers-each-page";  // (Questionnaire)


// IMAGE metadata has to be kept seperate because each image is stored as a Base64 encoded text
export const PAGE_HEADER_IMAGE_LEFT   = "image-page-header-left"; // (Questioniare)
export const PAGE_HEADER_IMAGE_CENTER = "image-page-header-center"; // (Questioniare)
export const PAGE_HEADER_IMAGE_RIGHT  = "image-page-header-right"; // (Questioniare)

export const PAGE_FOOTER1_IMAGE_LEFT   = "image-page-footer-left"; // (Questioniare)
export const PAGE_FOOTER1_IMAGE_CENTER = "image-page-footer-center"; // (Questioniare)
export const PAGE_FOOTER1_IMAGE_RIGHT  = "image-page-footer-right"; // (Questioniare)

export const PAGE_FOOTER2_IMAGE_LEFT   = "image-page-footer-left2"; // (Questioniare)
export const PAGE_FOOTER2_IMAGE_CENTER = "image-page-footer-center2"; // (Questioniare)
export const PAGE_FOOTER2_IMAGE_RIGHT  = "image-page-footer-right2"; // (Questioniare)

export const customButtonObj = {
    version: "1",
    objType: OBJ_TYPE_BUTTON,
    color: DEFAULT_SELECTED_COLOR,
    height: 20,
    width: 100,
    background: "white",
    borderColor: "white",
    border: "none",
    borderWidth: 0,
    borderRadius: 0,
    hoverColor: DEFAULT_SELECTED_COLOR,
    hoverFontWeight: "normal",
    hoverBackground: "white",
    hoverBorderColor: "white",
    hoverBorderWidth: 0,
}

export const customQuestionnaireMainPagesObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QNR_MAIN_PAGES,
    //format: "default",
    pagingMode: "full", // full or one
    answeredFirstQuestion: "show", // hide/show default is show, NOTE: Portal preview should never hide first question
    questionNumbering: "none", // none, all, page
    questionNums: "#", // #, #. #)
    questionNumsLikert: "none", // none or a format pattern
    questionNumsShowGroup: "none", // none or a format pattern

    buttonObj: {},
    text: cloneObj(customTextObject), // For future expansion to set font globally

    pageHeader: "no",           // no or yes
    pageHeaderLeftType: "none", // none, text or image
    pageHeaderLeftTextObj: "",
    pageHeaderCenterType: "none",
    pageHeaderCenterTextObj: "",
    pageHeaderRightType: "none",
    pageHeaderRightTextObj: "",

    pageFooter1: "no",           // no or yes
    pageFooter1LeftType: "none", // none, text or image
    pageFooter1LeftTextObj: "",
    pageFooter1CenterType: "none",
    pageFooter1CenterTextObj: "",
    pageFooter1RightType: "none",
    pageFooter1RightTextObj: "",

    pageFooter2: "no",           // no or yes
    pageFooter2LeftType: "none", // none, text or image
    pageFooter2LeftTextObj: "",
    pageFooter2CenterType: "none",
    pageFooter2CenterTextObj: "",
    pageFooter2RightType: "none",
    pageFooter2RightTextObj: ""
}

export function getCustomQuestionnaireMainPagesObj() {

    let tempObj = cloneObj(customQuestionnaireMainPagesObj);

    return tempObj;
}

export function setCustomQuestionnaireMainPagesObj(newObj) {

    let mainPagesObj = cloneObj(customQuestionnaireMainPagesObj);

    setObj(mainPagesObj, newObj);

    return mainPagesObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// CLOSE PAGE SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_CLOSE_PAGE_OBJ  = "custom-obj-qnr-close-page"; // NOTE: Close page defaults to included.
export const CUSTOM_QNR_CLOSE_PAGE_OBJ_LEGACY  = "custom-ddo-questionnaire-format-close-page"; // NOTE: Close page defaults to included.
export const QNR_CLOSE_PAGE_SCRIPT  = "user-script-bottom";
export const QNR_CLOSE_PAGE_SCRIPT_FORMAT_OBJ  = "user-script-bottom-format-obj"; // (Questionnaire)
export const QNR_CLOSE_PAGE_DEFAULT_TEXT  =  "Thank you! We appreciate you taking the time to share your feedback and help us in offering a premier client experience. Your answers have been recorded, please close your browser window."; // (Questionnaire)


export const customQuestionnaireClosePageObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QNR_CLOSE_PAGE,
    //format: "default", LEGACY not used anymore
    image: "greencheck",
    noGreenCheck: false,
    iconObj: {...customIconObject}, // Future expansion
    titleText: "",              // NOT used for future expansion
    titleFormat: "default",                         // NOT used for future expansion
    titleTextCustomObj: null,                       // NOT used for future expansion
    scriptFormat: "default",                        // NOT used for future expansion
    scriptCustomObj: null,                          // NOT used for future expansion
    defaultText: QNR_CLOSE_PAGE_DEFAULT_TEXT,
    defaultTextFormat: "default",
    defaultTextCustomObj: null,
}

export function getCustomQuestionnaireClosePageObj() {
    return cloneObj(customQuestionnaireClosePageObj);
}

export function setCustomQuestionnaireClosePageObj(newObj) {

    let closePageObj = cloneObj(customQuestionnaireClosePageObj);

    setObj(closePageObj, newObj);

    return closePageObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// SYSTEM BUTTONS SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const SYSTEM_BUTTON_VARIANT = "display-def-system-buttons-variant"     // outlined or contained, default variant is text
export const SYSTEM_BUTTON_SIZE = "display-def-system-buttons-size"  // small, medium, large DEFAULT size is large
export const SYSTEM_BUTTON_CUSTOM = "display-def-system-buttons-custom"
export const SYSTEM_BUTTON_CUSTOM_TEXT_COLOR = "display-def-system-buttons-custom-text-color"
export const SYSTEM_BUTTON_CUSTOM_TEXT_FONT = "display-def-system-buttons-custom-text-font"
export const SYSTEM_BUTTON_CUSTOM_WIDTH = "display-def-system-buttons-custom-width"
export const SYSTEM_BUTTON_CUSTOM_HEIGHT = "display-def-system-buttons-custom-height"
export const SYSTEM_BUTTON_CUSTOM_BORDER = "display-def-system-buttons-custom-border"   // Makes button have a border ie.. solid, DEFAULT none
export const SYSTEM_BUTTON_CUSTOM_BORDER_RADIUS = "display-def-system-buttons-custom-border-radius"
export const SYSTEM_BUTTON_CUSTOM_BORDER_WIDTH = "display-def-system-buttons-custom-border-width"
export const SYSTEM_BUTTON_CUSTOM_BORDER_COLOR = "display-def-system-buttons-custom-border-color"
export const SYSTEM_BUTTON_CUSTOM_BACKGROUND_COLOR = "display-def-system-buttons-custom-background-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_TEXT_COLOR = "display-def-system-buttons-custom-hover-text-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_BORDER_WIDTH = "display-def-system-buttons-custom-hover-border-width"
export const SYSTEM_BUTTON_CUSTOM_HOVER_BORDER_COLOR = "display-def-system-buttons-custom-hover-border-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_BACKGROUND_COLOR = "display-def-system-buttons-custom-hover-background-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_FONT_WEIGHT = "display-def-system-buttons-custom-hover-font-weight"  //ie.. bold

export const customSystemButtonsObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QNR_SYSTEM_BUTTONS,
    format: "default",
    variant: "",
    color: "default",           // default, custom
    selectedBorder: {...customColorObject},
    selectedBackground: {...customColorObject},
    selectedControl: {...customColorObject},
    selectedText: {...customColorObject},
    hoverBorder: {...customColorObject},
    hoverBackground: {...customColorObject},
    hoverControl: {...customColorObject},
    hoverText: {...customColorObject},
    unselectedBorder: {...customColorObject},
    unselectedBackground: {...customColorObject},
    unselectedControl: {...customColorObject},
    unselectedText: {...customColorObject},
    floatingLabel: {text: "", ...customColorObject},
    ddlbBorderColorAfterSelection: {...customColorObject},
    widthType: 'percentage',
    widthEntry: "100",
    width: "100%"
}

export function getCustomSystemButtonsObj() {
    return cloneObj(customSystemButtonsObj);
}

export function setCustomSystemButtonsObj(newObj) {

    let closePageObj = cloneObj(customSystemButtonsObj);

    setObj(closePageObj, newObj);

    return closePageObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// ALERTS SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

// NOTE: Not currently used, server sends error code.  To implement this server would need to send the questionnaire metadata or full template

export const ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY = "This key is invalid.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY = "This questionnaire key is invalid.";
export const ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED = "Completed and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED = "This questionnaire has already been completed and is no longer available.";
export const ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED = "Deactivated and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED = "This questionnaire has been deactivated.";
export const ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED = "Expired and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED = "This questionnaire has expired and is no longer available.";
export const ALERT_MESSAGE_QUESTIONNAIRE_CANCELED = "Canceled. The link remains active until the expiration date.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_CANCELED = "Questionnaire canceled. The link remains active until the expiration date.";
export const ALERT_MESSAGE_QUESTIONNAIRE_UNAVAILABLE = "This survey will be available for read-only viewing once the customer has opened the survey link.";

export const customQuestionnaireAlertsObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QNR_ALERT_TEXT,
    invalidKeyText: "This questionnaire key is invalid.",
    compleatedText: "This questionnaire has already been completed and is no longer available.",
    deactivatedText: "This questionnaire has been deactivated.",
    expiredText: "This questionnaire has expired and is no longer available.",
    canceledText: "Questionnaire canceled. The link remains active until the expiration date.",
}

export function getCustomQuestionnaireAlertsObj(alertType) {

     let tempObj = cloneObj(customQuestionnaireAlertsObj);

     return tempObj;
}

export function setCustomQuestionnaireAlertsObj(newObj) {

    let alertsObj = cloneObj(customQuestionnaireAlertsObj);

    setObj(alertsObj, newObj);

    return alertsObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// CATEGORY SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_DDO_CAT_FORMAT  = "custom-ddo-category-format"; // (Category)
export const CUSTOM_DDO_CAT_TITLE_BAR_LEFT = "custom-ddo-category-title-bar-left"; // (Category)
export const CUSTOM_DDO_CAT_TITLE_BAR_CENTER = "custom-ddo-category-title-bar-center"; // (Category)
export const CUSTOM_DDO_CAT_TITLE_BAR_RIGHT = "custom-ddo-category-title-bar-right"; // (Category)

export const CATEGORY_NEW_PAGE  = "display-def-new-page";   // (Category) Page Number 1..x || close  (Question on SIMPLE questionnaires)
export const PAGE_OF  = "display-def-page-of";   // (Category) Page Number 1...

export const CATEGORY_TITLE_BAR_DEFAULT_HEIGHT = "46";

// Category title bar Left/Center/Right Text
export const CATEGORY_TITLE_BAR  = "display-def-category-title-bar"; // (Category)
export const CATEGORY_TITLE_BAR_LEFT  = "display-def-category-title-bar-left";  // (Category)
export const CATEGORY_TITLE_BAR_CENTER  = "display-def-category-title-bar-center"; // (Category)
export const CATEGORY_TITLE_BAR_RIGHT  = "display-def-category-title-bar-right";  // (Category)


export const customCategoryObject = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_CAT,
    portalPageBreak: false,
    titlebar: false,
    height: CATEGORY_TITLE_BAR_DEFAULT_HEIGHT,
    color: {
        ...customColorObject
    }
}

export function getCustomCategoryObjectDefault() {
    let customObj = cloneObj(customCategoryObject);

    customObj.color.color = COLOR_PRIMARY_BLUE;

    return customObj;
}

export function setCustomCategoryObject(currentObj) {
    let categoryObj = getCustomCategoryObjectDefault();

    setObj(categoryObj, currentObj); // Set Values into default obj

    return categoryObj;
}

export function getCategoryTitleBarTextDefaultObject() {
    let customObj = cloneObj(customTextObject);

    customObj.fontSizeEntry = "21";
    customObj.variant = "h4";

    return customObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// GROUP SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_DDO_GRP_FORMAT  = "custom-ddo-group-format"; // (Group)

export const customLikertObject = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_LIKERT,
    alignAnswersRight: false,
    alignQuestionsAndAnswers: false,
    indent: 0,
    title: "none",
    titleType: "none",     // standard, graybar
    titleLeft: "",
    titleCenter: "",
    titleRight: "",
    titleColor: "gray",
    backgroundColor: "gray",
    iconButtonBorderWidth: "1px",
    iconSquareBorderWidth: "1px",
    iconUnselectedTextColor: "black",
    iconUnselectedColor: "black",
    titleObj: cloneObj(customTextObject),
    responsiveLabelsObj: cloneObj(customTextObject)
}

export const customGroupObject = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_GRP,
    likertObj: cloneObj(customLikertObject)
}

export function getCustomGroupObj() {
    let groupObj = cloneObj(customGroupObject);

    return groupObj;
}

export function setCustomGroupObj(newObj) {
    let groupObj = cloneObj(customGroupObject);

    setObj(groupObj, newObj);

    return groupObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// QUESTION TYPES SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


export const YES_NO = "YES_NO";
export const CHECKBOX = "CHECKBOX";
export const RADIO = "RADIO";
export const LIKERT_RADIO = "LIKERT_RADIO";
export const NUMBER = "NUMBER";
export const CURRENCY = "CURRENCY";
export const PHONE = "PHONE";
export const DATE = "DATE";
export const DATE_RANGE = "DATE_RANGE";
export const EMAIL = "EMAIL";
export const FREE_TEXT = "FREE_TEXT";
export const ADDRESS_FULL = "ADDRESS_FULL";
export const ZIPPOSTAL_ONLY = "ZIPPOSTAL_ONLY";
export const STATE_ONLY = "STATE_ONLY";


export const getLabel = {
    [YES_NO]: "Yes/No",
    [CHECKBOX]: "Checkbox",
    [RADIO]: "Single Select",
    [LIKERT_RADIO]: "Likert",
    [NUMBER]: "Number",
    [CURRENCY]: "Currency",
    [PHONE]: "Phone",
    [DATE]: "Date",
    [DATE_RANGE]: "Date Range",
    [EMAIL]: "Email",
    [FREE_TEXT]: "Free Text",
    [ADDRESS_FULL]: "Address",
    [ZIPPOSTAL_ONLY]: "ZIP",
    [STATE_ONLY]: "State",
};


export const supported = {
    FREE_TEXT,
    YES_NO,
    RADIO,
    CHECKBOX,
    LIKERT_RADIO,
    DATE,
    DATE_RANGE,
    EMAIL,
    PHONE,
    CURRENCY,
    NUMBER,
    ADDRESS_FULL,
    ZIPPOSTAL_ONLY,
    STATE_ONLY,
};


export const questionTypesList = [
    {value: "FREE_TEXT", text: "Free Text"},
    {value: "YES_NO", text: "Yes/No"},
    {value: "RADIO", text: "Single Select"},
    {value: "CHECKBOX", text: "Checkbox"},
    {value: "LIKERT_RADIO", text: "Likert"},
    {value: "DATE", text: "Date"},
    {value: "DATE_RANGE", text: "Date Range"},
    {value: "EMAIL", text: "Email"},
    {value: "PHONE", text: "Phone"},
    {value: "CURRENCY", text: "Currency"},
    {value: "NUMBER", text: "Number"},
    {value: "ADDRESS_FULL", text: "Address"},
    {value: "ZIPPOSTAL_ONLY", text: "ZIP"},
    {value: "STATE_ONLY", text: "State"}
];


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// QUESTION SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_DDO_QUE_FORMAT  = "custom-ddo-question-format"; // (Question) // Format varies depending on question type

export const KEY_METRIC_CATEGORY_QUESTION = "reporting-category-question"; // Changed to Key Metric Category in UI


// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: FREE_TEXT, EMAIL, PHONE, NUMBER, CURRENCY, DATE, ZIPPOSTAL_ONLY
// ---------------------------------------------------------------------------------------------------------------------

export const customQuestionTextObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QUE_TEXT,
    questionType: null,
    style: "outlined",          // outlined, standard, filled
    size: "small",             // small, medium
    widthType: 'percentage',   // percentage, pixels
    widthEntry: "100",          // numeric width
    width: "100%",             // numeric width + "px"
    max: "255",
    multiline: false,
    maxRows: 1,
    labelPosition: "none",      // none, above, below
    label: "",
    labelDisplay: "default",    // default, custom
    color: "default",
    text: cloneObj(customTextObject),
    selectedFloatingLabel: {...customColorObject},
    hoverFloatingLabel: {...customColorObject},
    unselectedFloatingLabel: {text: "", ...customColorObject},
    placeHolder: {text: "", ...customColorObject},
    selectedBorder: {...customColorObject},
    selectedBackground: {...customColorObject},
    hoverBorder: {...customColorObject},
    hoverBackground: {...customColorObject},
    unselectedBorder: {...customColorObject},
    unselectedBackground: {...customColorObject},
    isNegative: false,
    isPercentage: false,
    isDecimal: false,
    decimalScale: 2,
    zipFormat: "#####",
    thousandSeparator: true
};

export function getCustomQuestionTextObj(questionType) {

    let questionObj = cloneObj(customQuestionTextObj);

    questionObj.questionType = questionType;
    switch (questionType) {
        case FREE_TEXT:
            questionObj.unselectedFloatingLabel.text = getLabel[FREE_TEXT];
            break;
        case EMAIL:
            questionObj.unselectedFloatingLabel.text = getLabel[EMAIL];
            break;
        case PHONE:
            questionObj.unselectedFloatingLabel.text = getLabel[PHONE];
            break;
        case CURRENCY:
            questionObj.unselectedFloatingLabel.text = getLabel[CURRENCY];
            questionObj.isDecimal = true;
            break;
        case NUMBER:
            questionObj.unselectedFloatingLabel.text = getLabel[NUMBER];
            break;
        case ZIPPOSTAL_ONLY:
            questionObj.unselectedFloatingLabel.text = getLabel[ZIPPOSTAL_ONLY];
            break;
    }

    return questionObj;
}

export function setCustomQuestionTextObj(newObj) {

    let questionObj = cloneObj(customQuestionTextObj);

    setObj(questionObj, newObj);

    return questionObj;
}

// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: SINGLE SELECT and YES_NO and STATE_ONLY
// ---------------------------------------------------------------------------------------------------------------------

export const DISPLAY_DEF_SELECT_NO_QUESTION_NUMBER  = "display-def-select-no-question-number";  // (Question SELECT (DDLB))

export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_COLOR  = "display-def-responsive-label-color"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_SIZE  = "display-def-responsive-label-size"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_ALIGN  = "display-def-responsive-label-align"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_WEIGHT  = "display-def-responsive-label-weight"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_STYLE  = "display-def-responsive-label-style"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_OPACITY  = "display-def-responsive-label-opacity"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_FONT  = "display-def-responsive-label-font"; // (Group, Question, Answer) responsive label override

export const USE_CUSTOM_QUESTION_FOR_RESPONISVE_LABEL  = "display-def-use-custom-question-for-responsive-label"; // (Group, Question)
export const USE_CUSTOM_DESC_FOR_RESPONISVE_LABEL  = "display-def-use-custom-desc-for-responsive-label"; // (Group, Question)

export const customQuestionSingleSelectObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QUE_RADIO,
    questionType: null,
    displayAs: "single",        // single, likert
    style: "radios",            // radios, ddlb, standard, clear, outlined
    likertStyle: "radios",      // radios, buttongroup (squares), circle
    circleType: "standard",     // standard, primary, alternate
    likertObj: cloneObj(customLikertObject),
    showSelectedLabel: "no",    // no, yes
    arrange: "row",             // row, side, wrap
    state: "",
    property: "",
    text: cloneObj(customTextObject),
    color: "default",           // default, custom
    selectedBorder: {...customColorObjectShort},
    selectedBackground: {...customColorObjectShort},
    selectedControl: {...customColorObjectShort},
    selectedText: {...customColorObjectShort},
    hoverBorder: {...customColorObjectShort},
    hoverBackground: {...customColorObjectShort},
    hoverControl: {...customColorObjectShort},
    hoverText: {...customColorObjectShort},
    unselectedBorder: {...customColorObjectShort},
    unselectedBackground: {...customColorObjectShort},
    unselectedControl: {...customColorObjectShort},
    unselectedText: {...customColorObjectShort},
    floatingLabel: {text: "", ...customColorObjectShort},
    ddlbBorderColorAfterSelection: {...customColorObjectShort},
    widthType: 'percentage',
    widthEntry: "100",
    width: "100%",
    noQueNum: true                         // DISPLAY_DEF_SELECT_NO_QUESTION_NUMBER
};


export function getCustomQuestionSingleSelectObj(questionType) {

    let questionObj = cloneObj(customQuestionSingleSelectObj);

    questionObj.questionType = questionType;
    questionObj.style = (questionType === STATE_ONLY) ? "ddlb" : "radios";

    return questionObj;
}

export function setCustomQuestionSingleSelectObj(newObj) {

    let questionObj = cloneObj(customQuestionSingleSelectObj);

    setObj(questionObj, newObj);

    return questionObj;
}

export function legacyFixLikertObj(obj, propName) {
    let tempObj = obj === null ? {} : obj;
    let value = null;
    if (tempObj.hasOwnProperty("version")) {
        value = tempObj.likertObj[propName];
    } else if (tempObj !== null) {
        value = tempObj[propName];
    }
    return value;
}


// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: CHECKBOXS
// ---------------------------------------------------------------------------------------------------------------------

export const customQuestionCheckboxObj = {
    version: "1",
    objType:OBJ_TYPE_CUSTOM_QUE_CHECKBOX,
    questionType: questionTypes.CHECKBOX,
    style: "checkboxes",            // checkboxes, outlined
    arrange: "row",             // row, side, wrap
    color: "default",           // default, custom
    text: cloneObj(customTextObject),
    selectedControl: {...customColorObject},
    selectedText: {...customColorObject},
    hoverControl: {...customColorObject},
    hoverText: {...customColorObject},
    unselectedControl: {...customColorObject},
    unselectedText: {...customColorObject},
}

export function getCustomQuestionCheckboxObj() {

    let questionObj = cloneObj(customQuestionCheckboxObj);

    return questionObj;
}

export function setCustomQuestionCheckboxObj(newObj) {

    let questionObj = cloneObj(customQuestionCheckboxObj);

    setObj(questionObj, newObj);

    return questionObj;
}


// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: DATE_RANGE
// ---------------------------------------------------------------------------------------------------------------------
export const customQuestionDateRangeObj = {
    version: "1",
    objType:OBJ_TYPE_CUSTOM_QUE_DATE_RANGE,
    questionType: questionTypes.DATE_RANGE,
    text: cloneObj(customTextObject),
    fromDate: cloneObj(customQuestionTextObj),
    toDate: cloneObj(customQuestionTextObj)
}

export function getCustomQuestionDateRangeObj() {

    let questionObj = cloneObj(customQuestionDateRangeObj);

    questionObj.fromDate.floatingLabel = "From Date";
    questionObj.toDate.floatingLabel = "To Date";

    return questionObj;
}

export function setCustomQuestionDateRangeObj(newObj) {

    let questionObj = cloneObj(customQuestionDateRangeObj);

    setObj(questionObj, newObj);

    return questionObj;
}


// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: FULL_ADDDRESS
// ---------------------------------------------------------------------------------------------------------------------

export const customQuestionAddressTextObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QUE_ADDRESS_TEXT,
    widthType: "percentage",     // percentage, pixels
    widthEntry: "100",           // numeric width
    width:  "100%",              // numeric width + "px"
    max:  "",                    // Max characters allowed
    label:  "",
    floatingLabel:  "",
    placeHolder: ""
}

export function getCustomQuestionAddressTextObj(floatingLabel, max) {
    let addressTextObj = cloneObj(customQuestionAddressTextObj);

    addressTextObj.floatingLabel = floatingLabel;
    addressTextObj.max = max;

    return addressTextObj;
}

// Covers Question Types: ADDRESS
export const customQuestionAddressObj = {
    version: "1",
    objType: OBJ_TYPE_CUSTOM_QUE_ADDRESS,
    questionType: questionTypes.ADDRESS_FULL,
    style: "outlined",          // outlined, standard, filled
    size: "small",             // small, medium
    multiline: false,
    maxRows: 1,     // none, above, below
    selectedAnswer: {id: ""},
    color: "default",
    text: cloneObj(customTextObject),               // Font selected here will apply to all entry fields in address
    selectedFloatingLabel: {...customColorObject},
    hoverFloatingLabel: {...customColorObject},
    unselectedFloatingLabel: {...customColorObject},
    placeHolderColor: {...customColorObject},
    selectedBorder: {...customColorObject},
    selectedBackground: {...customColorObject},
    hoverBorder: {...customColorObject},
    hoverBackground: {...customColorObject},
    unselectedBorder: {...customColorObject},
    unselectedBackground: {...customColorObject},
    fiveDigitZip: true,
    labelPosition: "none",      // none, left, above, below
    labelsObj: cloneObj(customTextObject),         // Font selected here will apply to all labels in address
    street1Obj: getCustomQuestionAddressTextObj("Street 1", "255"),
    street2Obj: getCustomQuestionAddressTextObj("Street 2", "255"),
    cityObj: getCustomQuestionAddressTextObj("City", "255"),
    stateObj: getCustomQuestionAddressTextObj("State", "255"),
    zipObj: getCustomQuestionAddressTextObj("Zip", "10"),
}

export function getCustomQuestionAddressObj() {
    return cloneObj(customQuestionAddressObj);
}

export function setCustomQuestionAddressObj(newObj) {

    let questionObj = cloneObj(customQuestionAddressObj);

    setObj(questionObj, newObj);

    return questionObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// OTHER...
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



// export function getCustomLikertObject(templateType) {
//     let customObj = {...customLikertObject};
//
//     let textDefault = getTextDefaults(templateType);
//
//     customObj.fontSizeEntry = textDefault.fontSizeEntry;
//     customObj.fontSize = textDefault.fontSize;
//     customObj.variant = textDefault.variant;
//
//     return customObj;
// }

const customRadioObject = {
    backgroundColor: "",
    colorType: "default",
    colorIndex: null,
    colorEntry: "black",
    color: "black"
}


export const customQuestionSingleSelect = {
    indent: 0,
    arrange: "row",             // row, side, wrap
    state: "",
    property: "",
    color: "default",           // default, custom
    selectedBorder: customRadioObject,
    selectedBackground: customRadioObject,
    selectedControl: customRadioObject,
    selectedText: customRadioObject,
    unselectedBorder: customRadioObject,
    unselectedBackground: customRadioObject,
    unselectedControl: customRadioObject,
    unselectedText: customRadioObject
}


export const customQuestionObject =  {
    questionType: "",
    displayAs: "single",        // single, likert
    style: "radios",            // radios, ddlb, standard, clear, outlined
    likertStyle: "radios",      // radios, buttongroup (squares), circle
    circleType: "standard",     // standard, primary, alternate
    showSelectedLabel: "no",    //no, yes
    ...customLikertObject,
    ...customQuestionSingleSelect
};

export function getCustomQuestionObject(questionType) {
    let obj = {...customQuestionObject};

    obj.questionType = questionType;

    return obj;
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DEFAULT TEXT OBJECT BY TEMPLATE TYPE
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


export function getTextDefaults(templateType, textEntryType) {

    // NOTE: taken from QD Portal: getTitleDefaultVariant() in displayDefObjConstants.js

    let textDefaultsObj = {
        variant: "",
        fontSizeEntry: "",
        fontSize: ""
    };

    // const h1FontSize = '36px';  // Questionnaire
    // const h2FontSize = '31px';  // Category
    // const h3FontSize = '26px';  // Group
    // const h4FontSize = '21px';  // Question
    // const h5FontSize = '16px';  // Answer
    // const h6FontSize = '16px';  // Info/Comment Gray
    // const captionFontSize = '12px'; // Label
    // const subtitle1FontSize = '12px';  // Label Gray
    // const subtitle2FontSize = '12px';  // Label Secondary Color
    // const body1FontSize = '14px';
    // const body2FontSize = '14px'; // Italic
    // const overlineFontSize = '10px';
    // const buttonFontSize = '32px';

    switch(templateType) {
        case TEMPLATE.TYPE.QUESTIONNAIRE:
            switch(textEntryType) {
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.NAME:
                    textDefaultsObj.variant = "h1";
                    textDefaultsObj.fontSizeEntry = "36";
                    textDefaultsObj.fontSize = "36pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.DESC:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.APP_HEADER:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26px";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.PAGE_HEADER:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26px";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.PAGE_FOOTER1:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26px";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.PAGE_FOOTER2:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.START_PAGE_SCRIPT:
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.CLOSE_PAGE_SCRIPT:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.START_PAGE_TEXT:
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTIONNAIRE.CLOSE_PAGE_TEXT:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js Switch default E02"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.CATEGORY:
            switch(textEntryType) {
                case TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.NAME:
                    textDefaultsObj.variant = "h2";
                    textDefaultsObj.fontSizeEntry = "31";
                    textDefaultsObj.fontSize = "31pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.DESC:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.SCRIPT:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_LEFT:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_CENTER:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_RIGHT:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js.js Switch default E03"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.GROUP:
            switch(textEntryType) {
                case TEMPLATE.TEXT_ENTRY_TYPE.GROUP.NAME:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.GROUP.DESC:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js Switch default E04"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.QUESTION:
            switch(textEntryType) {
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTION.NAME:
                    textDefaultsObj.variant = "h6";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTION.DESC:
                    textDefaultsObj.variant = "body1";
                    textDefaultsObj.fontSizeEntry = "14";
                    textDefaultsObj.fontSize = "14pt";
                    break;
                case TEMPLATE.TEXT_ENTRY_TYPE.QUESTION.LABEL:
                    textDefaultsObj.variant = "caption";
                    textDefaultsObj.fontSizeEntry = "12";
                    textDefaultsObj.fontSize = "12pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js.js Switch default E05"); // eslint-disable-line
            }
            break;
        default:
            console.log("ArgoSelectPortalTextDisplayType.js Switch default E06"); // eslint-disable-line
    }

    return textDefaultsObj;
}


export function convertFontVariantToPoints(variant) {

    let fontSizePoints = "12"

    switch(variant) {
        case "h1":
            fontSizePoints = "36";
            break;
        case "h2":
            fontSizePoints = "31";
            break;
        case "h3":
            fontSizePoints = "26";
            break;
        case "h4":
            fontSizePoints = "21";
            break;
        case "h5":
            fontSizePoints = "16";
            break;
        case "h6":
            fontSizePoints = "16";
            break;
        case "caption":
            fontSizePoints = "12";
            break;
        case "subtitle1":
            fontSizePoints = "12";
            break;
        case "subtitle2":
            fontSizePoints = "12";
            break;
        case "body1":
            fontSizePoints = "14";
            break;
        case "body2":
            fontSizePoints = "14";
            break;
        case "overline":
            fontSizePoints = "10";
            break;
        // no default
    }


    return fontSizePoints;
}


export const textAlignList = [
    {value: "left", text: "Left"},
    {value: "center", text: "Center"},
    {value: "right", text: "Right"}
]

export const fontsList = [
    {value: "arial", text: "Arial"},
    {value: "arialblack", text: "Arial Black"},
    {value: "comic", text: "Comic Sans"},
    {value: "courier", text: "Courier New"},
    {value: "georgia", text: "Georgia"},
    {value: "impact", text: "Impact"},
    {value: "lucida", text: "Lucida"},
    {value: "lucidaSans", text: "Lucida Sans"},
    {value: "palatino", text: "Palatino"},
    {value: "roboto", text: "Roboto"},
    {value: "tahoma", text: "Tahoma"},
    {value: "trebuchet", text: "Trebuchet"},
    {value: "verdana", text: "Verdana"}
];

export const sizingTypeList = [
    {value: "variant", text: "Variant"},
    {value: "points", text: "Points"},
    {value: "pixels", text: "Pixels"}
];

export const variantTypesList = [
    {value: "h1", text: "h1"},
    {value: "h2", text: "h2"},
    {value: "h3", text: "h3"},
    {value: "h4", text: "h4"},
    {value: "h5", text: "h5"},
    {value: "h6", text: "h6"},
    {value: "body1", text: "body1"},
    {value: "body2", text: "body2"},
    {value: "caption", text: "caption"},
    {value: "subtitle1", text: "subtitle1"},
    {value: "subtitle2", text: "subtitle2"}
];

export const fontStyleList = [
    {value: "normal", text: "Normal"},
    {value: "italic", text: "Italic"},
    {value: "oblique", text: "Oblique"}
];

export const fontWeightList = [
    {value: "normal", text: "Normal"},
    {value: "bold", text: "Bold"},
    {value: "bolder", text: "Bolder"}
];





//export const CUSTOM_DDO_QUE_FORMAT  = "custom-ddo-question-format"; // (Question)
export const DISPLAY_AS  = "display-as"; // (Question)
export const DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT  = "display-def-align-question-answer-right"; // (Question)
export const DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS  = "display-def-likert-question-align-with-answers"; // (Question)
export const DISPLAY_DEF_SHOW_GROUP_STANDARD  = "display-def-show-group-standard"; // (Question)

export const CUSTOM_DDO_ANSWER_FORMAT  = "custom-ddo-answer-format"; // (Answer)

export const USE_LIKERT_GRAYBAR_HEADER  = "display-def-use-graybar-header"; // (Group/Question)
export const USE_LIKERT_STANDARD_HEADER  = "display-def-use-standard-header"; // (Group/Question)
export const USE_LIKERT_ANSWERS_HEADER  = "display-def-use-answers-header"; // (Group/Question)  // Answer Text is titles, like for radios and circles only

export const USE_LIKERT_RESPONSIVE_LABEL  = "display-def-use-responsive-label"; // (Answer) Buttongroup - Display value when selected beneath button group square or circle (Responsive Labels)
export const USE_LIKERT_DEFAULT_RESPONSIVE_LABEL  = "display-def-use-default-responsive-label"; // (Question) Buttongroup - Display DEFAULT value when selected beneath button group square or circle (Responsive Labels)

export const DISPLAY_DEF_RADIO_AS_BUTTON_GROUP  = "display-def-radio-as-buttongroup";
export const DISPLAY_DEF_BUTTON_AS_CIRCLE  = "display-def-button-as-circles";

export const DISPLAY_DEF_HIDE_BUTTONGROUP_NAME  = "display-def-hide-buttongroup-name";

export const DISPLAY_DEF_SHOW_QUESTION_GRAYBAR  = "display-def-show-question-graybar"

export const DISPLAY_DEF_LIKERT_TITLE_LEFT  = "display-def-likert-title-left"
export const DISPLAY_DEF_LIKERT_TITLE_CENTER  = "display-def-likert-title-center"
export const DISPLAY_DEF_LIKERT_TITLE_RIGHT  = "display-def-likert-title-right"





export function getButtonFormatCustomObj() {

    //let defaultFont = getDefaultVariant();

    let obj = {
        variant: "text",
        background: 'white',
        borderColor: '#ff0000',
        borderRadius: 3,
        border: 2,
        lineHeight: 1.5,
        color: 'red',
        height: 48,
        width: 100,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        fontColor: 'red',
        fontSize: 16,
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
        textOverride: "",
        lebelTextTransform: 'capitalize',
        hoverBackground: 'red',
        hoverBorderColor: '#0000ff',
        hoverBorderRadius: 3,
        hoverBorder: 2,
        hoverLineHeight: 1.5,
        hoverColor: 'yellow',
        hoverHeight: 48,
        hoverWidth: 100,
        hoverPadding: '0 30px',
        hoverBoxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        hoverFontColor: 'green',
        hoverFontSize: 16,
        hoverFontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    };

    return obj;
}


export function getFontFamily(font = "roboto") {

    let fontFamily = 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif';

    switch (font) {
        case "arial":
            fontFamily = "Arial, Arial, Helvetica, sans-serif";
            break;
        case "arialblack":
            fontFamily = "Arial Black, Arial Black, Gadget, sans-serif";
            break;
        case "comic":
            fontFamily = "Comic Sans MS, Comic Sans MS5, cursive";
            break;
        case "courier":
            fontFamily = "Courier New, Courier New, monospace";
            break;
        case "georgia":
            fontFamily = "Georgia1, Georgia, serif";
            break;
        case "impact":
            fontFamily = "Impact, Impact5, Charcoal6";
            break;
        case "lucida":
            fontFamily = "Lucida Console, Monaco5, monospace";
            break;
        case "lucidasans":
            fontFamily = "Lucida Sans Unicode, Lucida Grande, sans-serif";
            break;
        case "palatino":
            fontFamily = "Palatino Linotype, Book Antiqua3, Palatino, serif";
            break;
        case "roboto":
            fontFamily = 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif';
            break;
        case "tahoma":
            fontFamily = "Tahoma, Geneva, sans-serif";
            break;
        case "times":
            fontFamily = "Times New Roman, Times New Roman, Times, serif";
            break;
        case "trebuchet":
            fontFamily = "Trebuchet MS1, Trebuchet MS, sans-serif";
            break;
        case "verdana":
            fontFamily = "Verdana, Verdana, Geneva, sans-serif";
            break;
        // case "mssans":
        //     fontFamily = "MS Sans Serif4, Geneva, sans-serif";
        //     break;
        // case "msserif":
        //     fontFamily = "MS Serif4, New York6, serif";
        //     break;
        // no default
    }

    return fontFamily;
}












// Single Select Radios --------=---------------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_RADIO_CONTROL_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_RADIO_TEXT_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_SELECTED_RADIO_CONTROL_COLOR = DEFAULT_SELECTED_COLOR;

//export const DEFAULT_SELECTED_RADIO_TEXT_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_RADIO_CONTROL_COLOR = DEFAULT_SELECTED_COLOR;


export const DEFAULT_BUTTON_HEIGHT = 38;

// Single Select Outlined Radios --------=------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_OUTLINED_RADIO_CONTROL_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_OUTLINED_RADIO_TEXT_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_OUTLINED_RADIO_BORDER_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_OUTLINED_RADIO_BACKGROUND_COLOR = DEFAULT_WHITE;

export const DEFAULT_SELECTED_OUTLINED_RADIO_CONTROL_COLOR = DEFAULT_WHITE;
export const DEFAULT_SELECTED_OUTLINED_RADIO_TEXT_COLOR = DEFAULT_WHITE;
export const DEFAULT_SELECTED_OUTLINED_RADIO_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_SELECTED_OUTLINED_RADIO_BACKGROUND_COLOR = DEFAULT_SELECTED_COLOR;

// Single Select Buttons --------=--------------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_BUTTON_TEXT_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_BUTTON_BORDER_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_BUTTON_BACKGROUND_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_SELECTED_BUTTON_TEXT_COLOR = DEFAULT_WHITE;
export const DEFAULT_SELECTED_BUTTON_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_SELECTED_BUTTON_BACKGROUND_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_BUTTON_TEXT_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_BUTTON_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_BUTTON_BACKGROUND_COLOR = DEFAULT_WHITE;
export const DEFAULT_HOVER_SELECTED_BUTTON_TEXT_COLOR = DEFAULT_WHITE;
export const DEFAULT_HOVER_SELECTED_BUTTON_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_SELECTED_BUTTON_BACKGROUND_COLOR = DEFAULT_SELECTED_COLOR;

export const DEFAULT_BUTTON_BORDER_WIDTH = "2";
export const DEFAULT_BUTTON_BORDER_RADIUS = "0";
export const DEFAULT_BUTTON_BOX_SHADOW = "none";

// ---------------------------------------------------------------------------------------------------------------------
// SYSTEM BUTTONS
// ---------------------------------------------------------------------------------------------------------------------



// =====================================================================================================================
// -----------------------------=---------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// =====================================================================================================================

// Text overrides ------------------------------------------------------------------------------------------------------
// Array[0] = text: hide or show | left | center | right
// Array[1] = icon: none | info | comment | default  --- comment and icon can only be used with text show or left
// Array[2] = variant: default | 'h1' | 'h2' | 'h3'  | 'h4'  | 'h5'  | 'h6'  | 'subtitle1'  | 'subtitle2'  | 'body1'  | 'body2'  | 'caption'  | 'button'  | 'overline'

export const BLANK_NAME  = "display-def-blank-name";  // still used to blank name may need to keep








// ---------------------------------------------------------------------------------------------------------------------



// ---------------------------------------------------------------------------------------------------------------------

export const DO_NOT_USE_SHOW_GROUP  = "display-def-do-not-use-show-group"; //(Question), Likert groups share answers so questions with this tag will suppress show groups (NO UI IMPLEMENTATION)
export const DO_NOT_USE_ACTIONS  = "display-def-do-not-use-actions"; // (Question), Likert groups share answers so questions with this tag will suppress show groups (NO UI IMPLEMENTATION)



export const TEXT_FLOATING_LABEL  = "display-def-floating-label"; // (Question) - Text Entry
export const TEXT_PLACEHOLDER  = "display-def-placeholder"; // (Question) - Text Entry
export const TEXT_VARIANT  = "display-def-entry-variant"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
export const TEXT_SIZE  = "display-def-entry-size"; // (Question) - Text Entry small | medium, default = small
export const TEXT_WIDTH  = "display-def-entry-width"; // (Question) - Text Entry ###% default = 100%
export const TEXT_MAXLENGTH  = "display-def-maxlength"; // (Question) - Text Entry Default 80
export const TEXT_MULTILINE  = "display-def-multiline"; // (Question) - Text Entry Default false
export const TEXT_MAXROW  = "display-def-maxrow"; // (Question) - Text Entry Default 1
export const TEXT_LABEL_ABOVE  = "display-def-label-above"; // (Question) - Text Entry
export const TEXT_LABEL_BELOW  = "display-def-label-below"; // (Question) - Text Entry

export const TEXT_FLOATING_LABEL2  = "display-def-floating-label2"; // (Question) - Text Entry
export const TEXT_PLACEHOLDER2  = "display-def-placeholder2"; // (Question) - Text Entry
export const TEXT_VARIANT2  = "display-def-entry-variant2"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
export const TEXT_SIZE2  = "display-def-entry-size2"; // (Question) - Text Entry small | medium, default = small
export const TEXT_WIDTH2  = "display-def-entry-width2"; // (Question) - Text Entry ###% default = 100%
export const TEXT_MAXLENGTH2  = "display-def-maxlength"; // (Question) - Text Entry
export const TEXT_LABEL_ABOVE2  = "display-def-label-above2"; // (Question) - Text Entry
export const TEXT_LABEL_BELOW2  = "display-def-label-below2"; // (Question) - Text Entry




export const DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE  = "display-def-date-range-side-by-side";
export const DISPLAY_DATE_RANGE_AS_ONE_PER_ROW  = "display-def-date-range-one-per-row";

// ---------------------------------------------------------------------------------------------------------------------

export const METADATA_VALUE_PREFIX_PAGENUM  = "#pageNum";  // "#pageNum"
export const METADATA_VALUE_PREFIX_SRC  = "#src=";  // For passing an image URL

// ---------------------------------------------------------------------------------------------------------------------
// Single Select Question Display Overrides (Non-Likert)
// Default display is radio buttons if < 6 answers, otherwise DDLB
// Default position if 2 answers is side-by-side, otherwise one-per-row
// ---------------------------------------------------------------------------------------------------------------------

export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_RIGHT  = "display-def-as-radios-right";
export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_TOP  = "display-def-as-radios-top";
export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_BOTTOM  = "display-def-as-radios-bottom";

export const DISPLAY_SINGLE_SELECT_AS_RADIOS  = "display-def-as-radios"; // Question Type Yes/No radio is DEFAULT, Single Select
export const DISPLAY_SINGLE_SELECT_AS_OUTLINED_RADIOS  = "display-def-as-outlined-radios"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_STANDARD_BUTTONS  = "display-def-as-standard-buttons"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_CLEAR_BUTTONS  = "display-def-as-clear-buttons"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_DDLB  = "display-def-as-ddlb"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_BUTTONGROUP  = "display-def-as-buttongroup";

export const DISPLAY_SINGLE_SELECT_AS_ICON_ONLY  = "display-def-radio-as-icon-only"; // For single select with icons hide the radio button and use the icon as the button

export const DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE_WRAP  = "display-def-side-by-side-wrap";
export const DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE  = "display-def-side-by-side"; // Question Type Yes/No defulat is radio, side by side , Single Select
export const DISPLAY_SINGLE_SELECT_AS_ONE_PER_ROW  = "display-def-one-per-row"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_SIDE_BY_SIDE_SEPARATOR  = "display-def-side-by-side-separator"; // Question Type Yes/No, Single Select

// ---------------------------------------------------------------------------------------------------------------------
// Multi-Select Checkboxes
// ---------------------------------------------------------------------------------------------------------------------

// export const CB_SELECTED_COLOR_BACKGROUND  = "display-def-cb-selected-color-background"; // (Question) CB = checkbox - Outlined radios and checkboxes
// export const CB_SELECTED_COLOR_CONTROL  = "display-def-cb-selected-color-control"; // (group/question)
// export const CB_SELECTED_COLOR_TEXT  = "display-def-cb-selected-color-text";
// export const CB_UNSELECTED_COLOR_BACKGROUND  = "display-def-cb-unselected-color-background";
// export const CB_UNSELECTED_COLOR_CONTROL  = "display-def-cb-unselected-color-control"; // (group/question)
// export const CB_UNSELECTED_COLOR_TEXT  = "display-def-cb-unselected-color-text";


export const UNSELECTED_COLOR_BORDER  = "display-def-unselected-color-border";
export const UNSELECTED_COLOR_BACKGROUND  = "display-def-unselected-color-background";
export const UNSELECTED_COLOR_CONTROL  = "display-def-unselected-color-control"; // (group/question)
export const UNSELECTED_COLOR_TEXT  = "display-def-unselected-color-text";
export const SELECTED_COLOR_BORDER  = "display-def-selected-color-border"; // (group/question)
export const SELECTED_COLOR_BACKGROUND  = "display-def-selected-color-background"; // (group/question)
export const SELECTED_COLOR_CONTROL  = "display-def-selected-color-control"; // (group/question)
export const SELECTED_COLOR_TEXT  = "display-def-selected-color-text";
export const HOVER_COLOR_BORDER  = "display-def-hover-color-border"; // (group/question)
export const HOVER_COLOR_BACKGROUND  = "display-def-hover-color-background"; // (group/question)
export const HOVER_COLOR_CONTROL  = "display-def-hover-color-control"; // (group/question)
export const HOVER_COLOR_TEXT  = "display-def-hover-color-text";
export const HOVER_SELECTED_COLOR_BORDER  = "display-def-hover-selected-color-border"; // (group/question)
export const HOVER_SELECTED_COLOR_BACKGROUND  = "display-def-hover-selected-color-background"; // (group/question)

//export const HOVER_SELECTED_COLOR_CONTROL  = "display-def-hover-selected-color-control"; // (group/question)
export const HOVER_SELECTED_COLOR_TEXT  = "display-def-hover-selected-color-text";

export const BUTTON_BORDER_WIDTH  = "display-def-button-border-width";
export const BUTTON_BORDER_RADIUS  = "display-def-button-border-radius";
export const BUTTON_BOX_SHADOW  = "display-def-button-box-shadow";
export const BUTTON_HEIGHT  = "display-def-button-height";

export const DEFAULT_WIDTH  = "365px"; // (question) radios, outlined buttons, outlined checkboxes
export const OVERRIDE_WIDTH  = "display-def-override-width"; // (question) radios, outlined buttons, outlined checkboxes value = "12px" pixel width
export const CENTER_ANSWERS  = "display-def-center-answers"; // (question) radios, outlined buttons, outlined checkboxes value = "12px" pixel width

//export const DISPLAY_CHECKBOXES_AS_DDLB  = "display-def-checkboxes-as-ddlb";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force DDLB
//export const DISPLAY_CHECKBOXES_AS_CHECKBOXES  = "display-def-checkboxes-as-checkboxes";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force checkboxes
export const DISPLAY_CHECKBOXES_AS_OUTLINED  = "display-def-checkboxes-as-outlined";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force checkboxes

// ---------------------------------------------------------------------------------------------------------------------
//
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// Single Select Question Display Overrides (Likert)
// ---------------------------------------------------------------------------------------------------------------------


//export const USE_LIKERT_GRAYBAR_HEADER  = "display-def-use-graybar-header"; // (Group/Question)
//export const USE_LIKERT_STANDARD_HEADER  = "display-def-use-standard-header"; // (Group/Question)
//export const USE_LIKERT_ANSWERS_HEADER  = "display-def-use-answers-header"; // (Group/Question)  // Answer Text is titles, like for radios and circles only

export const DEFAULT_GRAYBAR_COLOR  = "#EEEEEE"; // (Group/Question)
export const DEFAULT_GRAYBAR_HEIGHT  = "40px"; // (Group/Question)

export const USE_LIKERT_GRAYBAR_COLOR  = "display-def-use-graybar-color"; // (Group, Question) graybar title backgrond color
export const USE_LIKERT_GRAYBAR_HEIGHT  = "display-def-use-graybar-height"; // (Group, Question) graybar title height
export const USE_CUSTOM_QUESTION_FOR_TITLE  = "display-def-use-custom-question-for-title"; // (Group, Question) graybar title overrides
export const USE_CUSTOM_DESC_FOR_TITLE  = "display-def-use-custom-desc-for-title"; // (Group, Question) graybar title overrides

export const LIKERT_HEADER_TITLE_LEFT  = "display-def-likert-title-left"; // (Group) Title headers
export const LIKERT_HEADER_TITLE_CENTER  = "display-def-likert-title-center"; // (Group) Title headers
export const LIKERT_HEADER_TITLE_RIGHT  = "display-def-likert-title-right"; // (Group) Title headers

export const HIDE_BUTTONGROUP_NAME  = "display-def-hide-buttongroup-name";  // (Answer) Buttongroup - Suppress the display of the number in the button of a buttongroup // (Answer) Radios - don't show title above

//export const USE_LIKERT_RESPONSIVE_LABEL  = "display-def-use-responsive-label"; // (Answer) Buttongroup - Display value when selected beneath button group square or circle (Responsive Labels)
//export const USE_LIKERT_DEFAULT_RESPONSIVE_LABEL  = "display-def-use-default-responsive-label"; // (Group/Question) Buttongroup - Display DEFAULT value when selected beneath button group square or circle (Responsive Labels)

export const INDENT_NAME  = "display-def-indent-name";  // Indent any name, value = pixel indent ie.. "20px"

//export const DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT  = "display-def-align-question-answer-right";  // (Questionnaire)(Group)(Question) Align question answers right
export const DISPLAY_DEF_ALIGN_ANSWERS_WITH_QUESTION  = "display-def-likert-question-align-with-answers";  // (Questionnaire)(Group)(Question) Align question answers right

export const DISPLAY_SINGLE_SELECT_AS_LIKERT  = "display-def-as-likert";  // Format as if it were a LIKERT, this is a workaround for show groups not currently developed

export const DISPLAY_BUTTONS_AS_CIRCLES  = "display-def-button-as-circles"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_COLORS  = "display-def-button-as-circles-colors"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_COLORS_ALT  = "display-def-button-as-circles-colors-alt"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID  = "display-def-button-as-circles-pyramid"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS  = "display-def-button-as-circles-pyramid-colors"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS_ALT  = "display-def-button-as-circles-pyramid-colors-alt"; // (Question) Buttongroup


// export const DISPLAY_AS_LIKERT_LABELS  = "display-def-label-placement";  // (Question) - display labels on bottom or start or end
//
// export const LIKERT_FORMAT_WIDTHS  = "display-def-likert-widths"; // (Likert Group Widths in numbers the % is added later question|answers
// export const LIKERT_QUESTION_ABOVE  = "display-def-likert-question-above"; // (Likert Group Widths in numbers the % is added later question|answers
// export const LIKERT_LABELS_ALL_QUESTIONS  = "display-def-likert-labels-all-questions"; // (Likert Group Widths in numbers the % is added later question|answers
// export const LIKERT_LABELS_BOTTOM  = "display-def-likert-labels-bottom"; // (Likert Group Widths in numbers the % is added later question|answers
// export const LIKERT_QUESTION_ALIGN_ADJUSTMENT  = "display-def-likert-question-align-adjustment"; // (Likert adjust vertical alignment of question name when inline or above
export const LIKERT_QUESTION_ALIGN_WITH_ANSWERS  = "display-def-likert-question-align-with-answers"; // (Likert align question text with answers


export const LIKERT_QUESTION_SPACE_AFTER  = "display-def-likert-question-space-after"; // (Likert space after question default is 10px
export const LIKERT_ANSWERS_SPACE_AFTER  = "display-def-likert-answers-apace-after"; // (Likert space after answers default is 10px


export const DISPLAY_LIKERT_TITLE_COLOR  = "display-def-likert-title-color"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_SIZE  = "display-def-likert-title-size"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_ALIGN  = "display-def-likert-title-align"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_WEIGHT  = "display-def-likert-title-weight"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_STYLE  = "display-def-likert-title-style"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_OPACITY  = "display-def-likert-title-opacity"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_FONT  = "display-def-likert-title-font"; // (Group/Question) Likert title text overrides

export const DISPLAY_LIKERT_TITLEBAR_AFTERSPACE_HEIGHT  = "display-def-likert-titlebar-afterspace-height"; // (Group, Question) responsive label override



// ---------------------------------------------------------------------------------------------------------------------
// TEXT ENTRIES
// ---------------------------------------------------------------------------------------------------------------------


export const DISPLAY_DEF_TEXT_PLACEHOLDER_COLOR  = "display-def-text-placeholder-color";  // (Question) TEXT ENTRY Placeholder Color
export const DISPLAY_DEF_TEXT_PLACEHOLDER_OPACITY  = "display-def-text-placeholder-opacity";  // (Question) TEXT ENTRY Placeholder Opacity






// ---------------------------------------------------------------------------------------------------------------------
// ANSWSERS SECTION
// ---------------------------------------------------------------------------------------------------------------------

//export const ANSWER_LABEL_TEXT_COLOR  = "display-def-answer-label-text-color"; // (question) label text color
export const ANSWER_TEXT_MATCH_QUESTION_NAME  = "display-def-answer-match-question-name"; // (question) match custom question (value can be font size pt override ie.. "10pt"
export const ANSWER_TEXT_MATCH_QUESTION_DESC  = "display-def-answer-match-question-desc"; // (question) match custom question (value can be font size pt override ie.. "10pt"

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------












