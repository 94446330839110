import * as icon from "./iconConstants";


export const numberOfAnswersList = [
    {value: "2", text: "2 Answers"},
    {value: "3", text: "3 Answers"},
    {value: "4", text: "4 Answers"},
    {value: "5", text: "5 Answers"},
    {value: "6", text: "6 Answers"},
    {value: "7", text: "7 Answers"},
    {value: "10", text: "10 Answers"},
    {value: "11", text: "11 Answers (NPS)"},
];


export const buildAddAnswerTemplateMenuItemsArray = (answerCount) => {

    let menuItemArray = [];
debugger;
    switch (answerCount) {
        case "2":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "thumbs", text: "Thumbs"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        case "3":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "stars", text: "Stars"},
                {value: "facesA", text: "Emojis Faces (Sad to Happy"},
                {value: "facesD", text: "Emojis Faces (Happy to Sad)"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        case "4":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "stars", text: "Stars"},
                {value: "facesA", text: "Emojis Faces (Sad to Happy"},
                {value: "facesD", text: "Emojis Faces (Happy to Sad)"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        case "5":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "stars", text: "Stars"},
                {value: "facesA", text: "Emojis Faces (Sad to Happy"},
                {value: "facesD", text: "Emojis Faces (Happy to Sad)"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        case "6":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "stars", text: "Stars"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        case "7":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "stars", text: "Stars"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        case "10":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "stars", text: "Stars"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        case "11":
            menuItemArray = [
                {value: "radios", text: "Radios"},
                {value: "squares", text: "Squares"},
                {value: "circles", text: "Circles"}
            ]
            break;
        // no default
    }

    return menuItemArray;
}


export const getLikertModes = (likertAddAnswerTemplateFormat, likertAddAnswerTemplate) => {

    let likertModes = [];

    if (likertAddAnswerTemplateFormat === "stars" || likertAddAnswerTemplateFormat === "facesA" || likertAddAnswerTemplateFormat === "facesD") {
        likertModes = icon.likertModesStarsAndFaces;
    } else if (likertAddAnswerTemplateFormat === "circles" && likertAddAnswerTemplate === "11") {
        likertModes = icon.likertModesCircles11;
    } else {
        likertModes = icon.likertModesOther;
    }

    return likertModes;
}


export const getLikertAnswerTextDisplay = (likertAddAnswerTemplateFormat, likertAddAnswerTemplate, likertMode) => {

    let likertAnswerTextDisplay = [];

    if (likertAddAnswerTemplateFormat === "radios" || likertAddAnswerTemplateFormat === "thumbs") {

        likertAnswerTextDisplay = icon.likertTitlesRadiosAndThumbs;

    } else if (likertAddAnswerTemplateFormat === "stars") {

        if (likertAddAnswerTemplate === "4" || likertAddAnswerTemplate === "6") {
            likertAnswerTextDisplay = icon.likertTitlesStarsEven;
        } else {
            likertAnswerTextDisplay = icon.likertTitlesALlOptions;
        }
    } else if (likertAddAnswerTemplateFormat === "facesA" || likertAddAnswerTemplateFormat === "facesD") {

        likertAnswerTextDisplay = icon.likertTitlesALlOptions;

    // } else if (likertAddAnswerTemplateFormat === "circles" && likertAddAnswerTemplate === "11" && (likertMode === icon.LIKERT_ICON_MODE_COLORS || likertMode === icon.LIKERT_ICON_MODE_COLORS_ALT)) {
    //
    //     likertAnswerTextDisplay = icon.likertTitlesOther;

    } else if (likertAddAnswerTemplateFormat === "circles" || likertAddAnswerTemplateFormat === "squares") {

        likertAnswerTextDisplay = icon.likertTitlesCirclesAndSquares;

    } else {
        likertAnswerTextDisplay = icon.likertTitlesOther;
    }

    return likertAnswerTextDisplay;
}


export const getLikertResponsiveLabels= (likertAddAnswerTemplateFormat) => {

    let likertResponsiveLabels = [];

    if (likertAddAnswerTemplateFormat === "thumbs") {
        likertResponsiveLabels = icon.likertResponsiveLabelsOther;
    } else {
        likertResponsiveLabels = icon.likertResponsiveLabels;
    }

    return likertResponsiveLabels;
}
