import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoColorInput from "./ArgoColorInput";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import * as portalShared from "../../portal/shared/metadataConstantsAndUtilities"
import {getCustomTextObj} from "../../portal/shared/metadataConstantsAndUtilities";


class ArgoTextDisplayCustom extends Component {

    constructor(props) {
        super(props);

        if (props.initToObj === null) {
            this.state = portalShared.getCustomTextObj(this.props.templateType, this.props.textEntryType);
        }
        else {
            this.state = portalShared.setCustomTextObject(props.initToObj);
        }
    }

    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let stateObj = {[fieldName]: value};

        switch(fieldName) {
            case "fontSizeEntry":
                if (this.state.fontSizing === "pixels") {
                    stateObj = {[fieldName]: value, "fontSize": value+"px"};
                }
                else {
                    stateObj = {[fieldName]: value, "fontSize": value+"pt"};
                }
                break;
            default:
                console.log("ArgoTextDisplayCustom.js  Switch default E01"); // eslint-disable-line
        }

        this.setState(stateObj, () => {this.setMetadataValue()});
    };

    onSelectChange = (event, fieldName, menuItemText) => {
        let selected = event.target.value;

        let stateObj = {[fieldName]: selected};

        switch(fieldName) {
            case "font":
                stateObj = {[fieldName]: selected, fontFamily: portalShared.getFontFamily(selected)};
                break;
            case "fontSizing":
            case "variant":

                let fontSizing = fieldName === "fontSizing" ? selected : this.state.fontSizing;
                let variant = fieldName === "variant" ? selected : this.state.variant;

                let fontSizePoints = portalShared.convertFontVariantToPoints(variant);

                stateObj = {fontSizing: fontSizing, variant: variant, "fontSize": fontSizePoints + "pt", "fontSizeEntry": fontSizePoints};
                break;
            default:
                console.log("ArgoTextDisplayCustom.js  Switch default E03"); // eslint-disable-line

        }

        // After setting the state, call setMetadataValue to update the template
        this.setState(stateObj, () => {this.setMetadataValue()});
    };

    setMetadataValue = () => {

        let valueObj = portalShared.setCustomTextObject(this.state);

        let mpo = this.props.metadataPropsObj;

        let setCustomMetadataFunction = this.props.useMetadataPropsObjAltFunction ? mpo.setCustomMetadataFunctionAlt : mpo.setCustomMetadataFunction;

        // Check to see if a set matadata callback was passed as a prop, if not use the callback passed in the mpo (metadata props object).
        if (this.props.setCustomMetadata) {
            this.props.setCustomMetadata(this.props.fieldTextObjName, this.props.fieldName, 'default', this.props.metadataTag, valueObj);
        }
        else {
            this.setState({metadataValue: JSON.stringify(valueObj)}, () => {mpo.setCustomMetadataFunction(this.props.fieldTextObjName, mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, "custom", mpo.metadataTag, valueObj)});
        }
    };

    setColor = (colorObj) => {

        this.setState(colorObj, () => {this.setMetadataValue()});
    };

    render() {

        return (
            <div id="ArgoTextDisplayCustom-container-div" style={{display: "flex", flexDirection: "row", width: '99%', backgroundColor: this.props.backgroundColorOverride, paddingTop: this.props.paddingTop}}>

                <div style={{width: "20px"}}></div>

                {this.props.showDisplay ?
                    <div style={{display: 'flex'}}>
                        <ArgoTextFieldSelectHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="textAlign"
                            label={this.props.displayLabel}
                            value={this.state.textAlign}
                            onChange={this.onSelectChange}
                            width="160px"
                            menuItems={portalShared.textAlignList}
                        />
                        <div style={{width: "20px"}}></div>
                    </div>
                    : ""
                }

                {this.props.showTitleEntry ?
                    <div style={{display: 'flex'}}>
                        <ArgoTextFieldHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="title"
                            label="Title"
                            value={this.state.title}
                            onChange={this.onTextChange}
                            width="140px"
                        />
                        <div style={{width: "20px"}}></div>
                    </div>
                    : ""
                }


                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="font"
                    label="Font"
                    value={this.state.font}
                    onChange={this.onSelectChange}
                    width="140px"
                    menuItems={portalShared.fontsList}
                />

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="fontSizing"
                    label="Sizing"
                    value={this.state.fontSizing}
                    onChange={this.onSelectChange}
                    width="110px"
                    menuItems={portalShared.sizingTypeList}
                />

                <div style={{width: "20px"}}></div>

                <div>
                {
                    (this.state.fontSizing === 'variant') ?
                        <ArgoTextFieldSelectHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="variant"
                            label="Variant"
                            value={this.state.variant}
                            onChange={this.onSelectChange}
                            width="110px"
                            menuItems={portalShared.variantTypesList}
                        />
                        :
                        <ArgoTextFieldHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="fontSizeEntry"
                            label={this.state.fontSizing === 'points' ? "Points" : "Pixels"}
                            value={this.state.fontSizeEntry}
                            type="number"
                            onChange={this.onTextChange}
                            width="70px"
                        />
                }
                </div>

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="fontStyle"
                    label="Style"
                    value={this.state.fontStyle}
                    onChange={this.onSelectChange}
                    width="110px"
                    menuItems={portalShared.fontStyleList}
                />

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="fontWeight"
                    label="Weight"
                    value={this.state.fontWeight}
                    onChange={this.onSelectChange}
                    width="110px"
                    menuItems={portalShared.fontWeightList}
                />

                <div style={{width: "20px"}}></div>

                <ArgoColorInput opacityFlag={true}
                                setColor={this.setColor}
                                initToObj={this.props.initToObj}
                                largeHexEntry={this.props.largeHexEntry}
                                index={this.props.index}/>

            </div>
        );
    }
}

ArgoTextDisplayCustom.defaultProps = {
    showDisplay: true,
    showTitleEntry: false,
    displayLabel: "Display",
    backgroundColorOverride: "#F8F8F8",
    largeHexEntry: true,
    paddingTop: "10px",
    useMetadataPropsObjAltFunction: false,
    index: 0,
    setCustomMetadata: undefined
};

ArgoTextDisplayCustom.propTypes = {
    templateType:  PropTypes.string,
    textEntryType:  PropTypes.string,
    showDisplay: PropTypes.bool,
    showTitleEntry: PropTypes.bool,
    fieldName: PropTypes.string,
    fieldTextObjName: PropTypes.string,
    initToObj: PropTypes.object,
    displayLabel: PropTypes.string,
    backgroundColorOverride: PropTypes.string,
    largeHexEntry: PropTypes.bool,
    paddingTop: PropTypes.string,
    metadataPropsObj: PropTypes.object,
    useMetadataPropsObjAltFunction: PropTypes.bool,
    index: PropTypes.number,
    setCustomMetadata: PropTypes.func
};

export default ArgoTextDisplayCustom;

