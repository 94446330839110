import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Typography from "@mui/material/Typography";
import {getCategoryTemplate} from "../../../actions/categoryTemplateActions";
import {
    initializeCategoryEditorCreate,
    initializeCategoryEditorUpdate,
    updateCategoryTemplate,
    clearCategoryEditor,
    validateCategoryNameAndQualifierUnique,
    addCategoryGroup,
    removeCategoryGroup,
} from "../../../actions/categoryEditorActions";
import {
    getGroupTemplate,
    createGroupTemplate,
    saveGroupTemplate
} from "../../../actions/groupTemplateActions";
import {saveQuestionTemplate} from "../../../actions/questionTemplateActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {expanderClear} from "../../../actions/expanderActions";
import GroupEditor from "../groups/GroupEditor";
import GroupViewerExpandable from "../groups/GroupViewerExpandable";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import ArgoColorInput from "../../common/ArgoColorInput";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoSelectPortalTextDisplayType from "../../common/ArgoSelectPortalTextDisplayType";
import ArgoTextDisplayCustom from "../../common/ArgoTextDisplayCustom";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import ArgoTextEntryWithCustomOverride from "../../common/ArgoTextEntryWithCustomOverride";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import QuestionEditor from "../questions/QuestionEditor";
import * as validate from "../../../utilities/validate";
import * as metadataUtils from "../../../utilities/metadata";
import * as metadata from "../../../utilities/metadata";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities"


class CategoryEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupTemplateSearch: "",
            openGroupReview: false,
            selectedGroupInfoKey: "",
            openQuestionReview: false,
            selectedQuestionInfoKey: "",
            isThisAnExistingGroup: "0",
            nameErrorText: " ",
            metadataErrorText: "",
            isLoading: false,
            groupList: [],
            editGroupKey: null,
            customCategoryObject: portalShared.getCustomCategoryObjectDefault(),
            leftTitle: metadata.defaultCustomMetadataObj,
            centerTitle: metadata.defaultCustomMetadataObj,
            rightTitle: metadata.defaultCustomMetadataObj,
            nameDisplay: metadata.defaultCustomMetadataObj,
            descDisplay: metadata.defaultCustomMetadataObj,
            scriptDisplay: metadata.defaultCustomMetadataObj,
            toolbarErrorText: "",
            openGroupEditor: false,
            groupTemplate: {},
            openQuestionEditor: false,
            questionTemplate: {},
            metadataSet: false,
            initialScroll: false
        };
    }

    componentDidMount() {

        if (this.props.templateKey || this.props.templateId) {
            if (this.props.categoryTemplate[this.props.templateKey] && (!this.props.templateId ||
                (this.props.categoryTemplate[this.props.templateKey].id === this.props.templateId))) {

                this.props.initializeCategoryEditorUpdate(this.props.categoryTemplate[this.props.templateKey]);
            }
            else if (this.props.templateKey) {
                this.setState({isLoading: true});
                this.props.getCategoryTemplate(this.props.templateKey, this.props.templateId);
            }

        }
        else {
            let template = {
                ...this.props.createInitialTemplate
            };

            this.props.initializeCategoryEditorCreate(template);
        }

        if (this.props.templateOriginal) {
            this.pageInit(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {

        if (!this.state.initialScroll) {
            let scrollTo = (this.props.scrollTo === "") ? "CategoryEditor-height-div" : this.props.scrollTo;
            let page = document.getElementById(scrollTo);
            if (page) {
                page.scrollIntoView(false);
                this.setState({initialScroll: true});
                return;
            }
        }

        // if templateOriginal is loaded and the templateKey matches the current templateKey and is different from the loaded template (check id here?)
        let isTemplateLoaded = (nextProps.templateOriginal && nextProps.templateOriginal.templateKey === nextProps.templateKey
            && nextProps.template.templateKey !== nextProps.templateKey);

        // The loaded template is stale and the correct template is loaded
        let editTemplateReady = nextProps.template.id !== nextProps.templateId &&
            (nextProps.categoryTemplate[nextProps.templateKey] && nextProps.categoryTemplate[nextProps.templateKey].id === nextProps.templateId);

        if (!this.state.metadataSet) {
            if (isTemplateLoaded && editTemplateReady) {
                this.setState({isLoading: false, groupList: nextProps.groupTemplateList});
                this.props.initializeCategoryEditorUpdate(nextProps.templateOriginal);

                this.pageInit(nextProps);
            }
            else {
                this.setState({groupList: nextProps.groupTemplateList});
            }
        }

        nextProps.groupKeys && nextProps.groupKeys.map((templateKey) => {
            if (!nextProps.groupTemplate[templateKey]) {
                nextProps.getGroupTemplate(templateKey);
            }
            return false;
        });

        this.checkForErrorsOnScreen(nextProps);
    }

    componentWillUnmount() {
        this.props.clearCategoryEditor();
    }

    // Allow child components to set errors on this page.
    setStateCallback = (stateObj, saveAfter = false, templateType) => {

        if ((templateType === TEMPLATE.TYPE.GROUP) && saveAfter) {
            this.setState(stateObj, () => this.groupEditorSave());
        }
        else if ((templateType === TEMPLATE.TYPE.QUESTION) && saveAfter) {
            this.setState(stateObj, () => this.questionEditorSave());
        }
        else {
            this.setState(stateObj, () => this.checkForErrorsOnScreen(this.props));
        }
    }

    pageInit = (currProps) => {

        let tempCustomCategoryObject = portalShared.cloneObj(this.state.customCategoryObject);

        let metadataObj = currProps.templateOriginal.metadata;
        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_DDO_CAT_FORMAT)) {
            // If there is already a customCategoryObject get the values
            tempCustomCategoryObject = portalShared.setCustomCategoryObject(JSON.parse(currProps.templateOriginal.metadata[portalShared.CUSTOM_DDO_CAT_FORMAT]));
        }

        let tempNameDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_NAME, tempCustomCategoryObject.titlebar);

        let tempDescDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_DESC, tempCustomCategoryObject.titlebar);

        this.setState({
            leftTitle: this.setTitleObject(portalShared.CUSTOM_DDO_CAT_TITLE_BAR_LEFT, metadataObj),
            centerTitle: this.setTitleObject(portalShared.CUSTOM_DDO_CAT_TITLE_BAR_CENTER, metadataObj),
            rightTitle: this.setTitleObject(portalShared.CUSTOM_DDO_CAT_TITLE_BAR_RIGHT, metadataObj),
            customCategoryObject: tempCustomCategoryObject,
            nameDisplay: metadata.setCustomMetadataObj(tempNameDisplayObj),
            //scriptDisplay: metadata.setCustomMetadataObj(tempScriptDisplayObj),
            descDisplay: metadata.setCustomMetadataObj(tempDescDisplayObj),
        });
    };

    setTitleObject = (metadataTag, metadataObj) => {
        let titleObj = {...metadata.defaultCustomMetadataObj};
        // If the title Left/Center/Right has a metadata tag then can be custom with object value, or a string "hide"
        if (metadataObj.hasOwnProperty(metadataTag)) {
            titleObj.metadataValue = metadataObj[metadataTag];
            titleObj.metadataValueObj = JSON.parse(titleObj.metadataValue);
            titleObj.selected = titleObj.metadataValueObj.selected;
        }
        return titleObj;
    }

    setTextObject = (obj) => {
        return {
            selected: obj.selected,
            metadataValue: obj.metadataValue,
            metadataValueObj: obj.metadataValueObj
        };
    }

    checkForErrorsOnScreen = (props) => {
        let nameErrorText = validate.templateNameAndQualifierUnique(props, "Category Name");
        let metadataErrorText = this.state.metadataErrorText;
        let toolbarErrorText = "";

        if (nameErrorText !== " ") {
            toolbarErrorText = nameErrorText;
        }

        if (metadataErrorText !== "") {
            toolbarErrorText = toolbarErrorText === "" ? this.state.metadataErrorText : toolbarErrorText + ",  " + this.state.metadataErrorText;
        }

        this.setState({nameErrorText: nameErrorText, metadataErrorText: metadataErrorText, toolbarErrorText: toolbarErrorText});
    };

    setMetadataErrorText = (metadataErrorText) => {
        this.setState({metadataErrorText: metadataErrorText, metadataSet: false}, () => this.checkForErrorsOnScreen(this.props));
    }

    metadataChanged = (updatedTemplate, depth) => {
        // Note: Timing issues was causing componentWillReceiveProps to trigger and a setState was canceling another setState
        this.setState({metadataSet: true}, () => this.props.updateCategoryTemplate(updatedTemplate, depth));
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "CANCEL":
                this.props.cancel();
                break;
            case "SAVE":
                this.saveCategory();
                break;
            case "BACK":
                this.setState({openGroupReview: false, openQuestionReview: false});
                break;
            // no default
        }
    }

    pickGroup = (groupTemplateKey) => {
        if (!this.props.groupKeys.includes(groupTemplateKey.templateKey)) {
            this.props.addCategoryGroup(groupTemplateKey.templateKey);
        }
        this.setState({groupTemplateSearch: ""});
    };

    onCheckboxChange = (event, fieldName) => {

        let tempCustomCategoryObject = {...this.state.customCategoryObject};

        tempCustomCategoryObject[fieldName] = event.target.checked;

        if ((fieldName === "titlebar") && (event.target.checked)) {

            this.setState({
                    customCategoryObject: tempCustomCategoryObject,
                    nameDisplay: {selected: "hide", metadataValue: "", metadataValueObj: "hide"},
                    descDisplay: {selected: "hide", metadataValue: "", metadataValueObj: "hide"},
                    //scriptDisplay: {selected: "hide", metadataValue: "", metadataValueObj: "hide"}
                }, () => {this.setMetadataValue()});
        }
        else if ((fieldName === "titlebar") && (!event.target.checked)) {

            this.setState({
                customCategoryObject: tempCustomCategoryObject,
                nameDisplay: {selected: "default", metadataValue: "", metadataValueObj: "default"},
                descDisplay: {selected: "default", metadataValue: "", metadataValueObj: "default"},
                //scriptDisplay: {selected: "default", metadataValue: "", metadataValueObj: "default"}
            }, () => {
                this.setMetadataValue()
            });
        }
        else {
            this.setState({customCategoryObject: tempCustomCategoryObject}, () => {this.setMetadataValue()});
        }

    };

    setMetadataValue = () => {

        let updatedTemplate = {...this.props.template};
        let metadata = {
            ...updatedTemplate.metadata
        };

        if ((this.state.customCategoryObject.portalPageBreak)|| (this.state.customCategoryObject.titlebar)) {
            metadata[portalShared.CUSTOM_DDO_CAT_FORMAT] = JSON.stringify(this.state.customCategoryObject);
        }
        else {
            delete metadata[portalShared.CUSTOM_DDO_CAT_FORMAT];
        }

        updatedTemplate.metadata = metadata;
        this.props.updateCategoryTemplate(updatedTemplate);
    };

    onTextChange = (event, fieldName) => {
        let value = event.target.value;

        if ((fieldName === "nameQualifier") && (value==="")) {
            value = null;
        }

        let updatedTemplate = {...this.props.template};
        updatedTemplate[fieldName] = value;

        this.props.updateCategoryTemplate(updatedTemplate);

        if (fieldName === "name" || fieldName === "nameQualifier")
        {
            this.props.validateCategoryNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier);
        }

    };

    onNumberChange = (event) => {
        event.preventDefault(); // Stops scroll wheel from double-clicks on single-click

        let value = event.target.value;

        let customCategoryObject = portalShared.cloneObj(this.state.customCategoryObject);
        customCategoryObject.height = value;
        this.setState({customCategoryObject: customCategoryObject}, () => {this.setMetadataValue()});
    }

    handleMetaChange = (fieldName, event, value) => {
        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};
        tempMetadata[fieldName] = value;
        updatedTemplate.metadata = tempMetadata;
        this.props.updateCategoryTemplate(updatedTemplate);
    };

    saveCategory = () => {

        let template = {
            ...this.props.template,
            groupKeys: [...this.props.groupKeys]
        };

        // If edit is being called from category expander, after QuestionnaireEditor state is set it calls saveCategoryTemplate
        if (this.props.categoryExpanderEdit) {
            this.props.categoryExpanderSetStateCallback({categoryTemplate: template}, true, TEMPLATE.TYPE.CATEGORY);
            return;
        }

        this.props.save(template);
    };

    onChangeIsThisAnExistingGroup = (event) => {
        let value = event.target.value;
        let newState = {
            isThisAnExistingGroup: value,
            groupTemplateSearch: (value)? this.state.groupTemplateSearch: ""
        };

        this.setState(newState);
    };

    setColorForPortalTitleBar = (colorObj) => {
        let customCategoryObject = portalShared.cloneObj(this.state.customCategoryObject);
        customCategoryObject.color = colorObj.color;
        this.setState({customCategoryObject: customCategoryObject}, () => {this.setMetadataValue()});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // GROUP EDITOR FUNCTIONS ------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    groupEditorCreate = (groupTemplate) => {
        if (this.state.editGroupKey) {
            // Update existing group
            this.props.saveGroupTemplate(groupTemplate, /** persistDrawer*/ true);
        } else {
            // Create new group
            this.props.createGroupTemplate(groupTemplate, true);
        }
        this.setState({isThisAnExistingGroup: "0", groupTemplateSearch: "", editGroupKey: null});
    };

    groupEditorClose = () => {
        this.setState({isThisAnExistingGroup: "0", groupTemplateSearch: "", editGroupKey: null});
    };

    groupEditorSave = () => {
        this.props.saveGroupTemplate(this.state.groupTemplate);
        this.setState({openGroupEditor: false});
    };

    groupEditorCancel = () => {
        this.setState({openGroupEditor: false});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // QUESTION EDITOR FUNCTIONS ------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    questionEditorSave = () => {
        this.props.saveQuestionTemplate(this.state.questionTemplate);
        this.setState({openQuestionEditor: false});
    };

    questionEditorCancel = () => {
        this.setState({openQuestionEditor: false});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {
        if (this.state.isLoading) {
            //TODO place a loading screen here
            return null;
        }

        if (this.state.openGroupReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.GROUP}
                templateKey={this.state.selectedGroupInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.isThisAnExistingGroup === "1") {

            let template = {
                name: this.state.groupTemplateSearch
            };

            return <GroupEditor title="Create Group"
                                createMode={true}
                                save={this.groupEditorCreate}
                                cancel={this.groupEditorClose}
                                showCreateToggle={this.isEditCreateSelector}
                                createInitialTemplate={template}
                                templateKey={this.state.editGroupKey}
                                width={this.props.width}
                    />;
        }

        // Edit from group expander
        if (this.state.openGroupEditor) {

            return <GroupEditor title="Edit Group"
                                save={this.groupEditorSave}
                                cancel={this.groupEditorCancel}
                                templateKey={this.state.groupTemplate.templateKey}
                                templateId={this.state.groupTemplate.id}
                                filter={this.props.filter}
                                templateMode={TEMPLATE.MODE.CURRENT}
                                groupExpanderEdit={true}
                                groupExpanderSetStateCallback={this.setStateCallback}
                                width={this.props.width}
                                scrollTo="CategoryEditor-container-outer-div"
                   />;
        }

        // Edit from question expander
        if (this.state.openQuestionEditor) {

            return <QuestionEditor title="Edit Question"
                                   save={this.questionEditorSave}
                                   cancel={this.questionEditorCancel}
                                   templateKey={this.state.questionTemplate.templateKey}
                                   templateId={this.state.questionTemplate.id}
                                   filter={this.props.filter}
                                   templateMode={TEMPLATE.MODE.CURRENT}
                                   questionDepth={0}
                                   questionExpanderEdit={true}
                                   questionExpanderSetStateCallback={this.setStateCallback}
                                   width={this.props.width}
                                   scrollTo="CategoryEditor-container-outer-div"
            />;
        }

        const metadataObj = (this.props.template && this.props.template.metadata) ? this.props.template.metadata : {};

        let propsObj = {
            fileName: "CategoryEditor",
            onTextChange: this.onTextChange,
            templateType: TEMPLATE.TYPE.CATEGORY,
            template: this.props.template,
            metadata: metadataObj,
            templateUpdateFunction: this.props.updateCategoryTemplate,
            setStateCallbackFunction: this.setStateCallback,
            setCustomMetadataFunction: metadataUtils.setCustomMetadata,
            depth: 0,
        }

        let categoryTemplateClone = portalShared.cloneObj(this.props.template);

        categoryTemplateClone.groupKeys = this.props.groupKeys; // Required by Portal Preview for group order/remove/add updates

        return (
            this.props.template.hasOwnProperty("templateKey") ?
                <div id="CategoryEditor-container-outer-div" style={{height: "100%"}}>

                    <ArgoToolbarHook
                        fileName="CategoryEditor"
                        fieldName="save-or-cancel"
                        toolbarErrorText={this.state.toolbarErrorText}
                        onClick={this.handleToolbarButtonClick}
                        width={this.props.width}
                    />

                    <div id="CategoryEditor-height-div" style={{height: "50px"}}></div>

                    <div>&nbsp;</div>

                    <div id="CategoryEditor-page-div" style={{height: "100%", width: "100%"}}>

                        <div id="CategoryEditor-container-inner-div"
                             style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                            <div id="CategoryEditor-title-div">
                                {this.props.title === "" ? "" :
                                    <div>
                                        <div>&nbsp;</div>
                                        <Typography style={{fontWeight: "bold"}}>{this.props.title}</Typography>
                                        <div>&nbsp;</div>
                                    </div>
                                }
                            </div>

                            <div id="CategoryEditor-pagebreak-container-div"
                                 style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                                <ArgoCheckboxHook
                                    fileName="CategoryEditor"
                                    fieldName="portalPageBreak"
                                    label="Portal page break"
                                    onChange={this.onCheckboxChange}
                                    checked={this.state.customCategoryObject.portalPageBreak}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoCheckboxHook
                                    fileName="CategoryEditor"
                                    fieldName="titlebar"
                                    label="Portal title bar"
                                    onChange={this.onCheckboxChange}
                                    checked={this.state.customCategoryObject.titlebar}
                                    width="150px"
                                />

                                <div style={{width: "20px"}}></div>

                                {this.state.customCategoryObject.titlebar ?
                                    <div id="CategoryTitleBar-div" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <ArgoTextFieldHook
                                        fileName="CategoryEditor"
                                        fieldName="height"
                                        index={0}
                                        type="number"
                                        label="Height"
                                        value={this.state.customCategoryObject.height}
                                        onChange={this.onNumberChange}
                                        width={60}
                                    />

                                    <div style={{width: "20px"}}>&nbsp;</div>

                                    <ArgoColorInput mode="title"
                                                    colorLabel={"Title Bar Color"}
                                                    fieldName={"customCategoryObject"}
                                                    initToObj={this.state.customCategoryObject.color}
                                                    opacityFlag={true}
                                                    largeHexEntry={false}
                                                    setColor={this.setColorForPortalTitleBar.bind(this)}
                                                    height={'17px'}
                                    /></div> : ""
                                }
                            </div>

                            {this.state.customCategoryObject.titlebar ?
                                <div id="CategoryEditor-titles-div" style={{width: '100%', backgroundColor: '#F8F8F8'}}>

                                    <div id="CategoryEditor-title-spacer-01-div" style={{height: "10px"}}/>

                                    <div style={{display: "flex", alignItems: "left", width: '100%'}}>

                                        <div style={{width: "20px"}}></div>

                                        <ArgoSelectPortalTextDisplayType
                                            templateType={TEMPLATE.TYPE.CATEGORY}
                                            textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_LEFT}
                                            fieldName="leftTitle.selected"
                                            fieldTextObjName="leftTitle"
                                            initTo={this.state.leftTitle.selected}
                                            hideOption={false}
                                            labelText={"Left Title"}
                                            metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_CAT_TITLE_BAR_LEFT}, propsObj)}
                                        />

                                        {(this.state.leftTitle.selected === 'custom') ?
                                            <ArgoTextDisplayCustom
                                                templateType={TEMPLATE.TYPE.CATEGORY}
                                                textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_LEFT}
                                                fieldName="leftTitle.selected"
                                                fieldTextObjName="leftTitle"
                                                initToObj={this.state.leftTitle.metadataValueObj}
                                                showDisplay={false}
                                                showTitleEntry={true}
                                                paddingTop={"0px"}
                                                metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_CAT_TITLE_BAR_LEFT}, propsObj)}
                                            />
                                            :
                                            <div></div>
                                        }
                                    </div>

                                    <div id="CategoryEditor-title-spacer-02-div" style={{height: "10px"}}/>

                                    <div style={{display: "flex", alignItems: "left", width: '100%'}}>

                                        <div style={{width: "20px"}}></div>

                                        <ArgoSelectPortalTextDisplayType
                                            templateType={TEMPLATE.TYPE.CATEGORY}
                                            textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_CENTER}
                                            fieldName="centerTitle.selected"
                                            fieldTextObjName="centerTitle"
                                            initTo={this.state.centerTitle.selected}
                                            hideOption={false}
                                            labelText={"Center Title"}
                                            metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_CAT_TITLE_BAR_CENTER}, propsObj)}
                                        />

                                        {(this.state.centerTitle.selected === 'custom') ?
                                            <ArgoTextDisplayCustom
                                                templateType={TEMPLATE.TYPE.CATEGORY}
                                                textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_CENTER}
                                                fieldName="centerTitle.selected"
                                                fieldTextObjName="centerTitle"
                                                initToObj={this.state.centerTitle.metadataValueObj}
                                                showDisplay={false}
                                                showTitleEntry={true}
                                                paddingTop={"0px"}
                                                metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_CAT_TITLE_BAR_CENTER}, propsObj)}
                                            />
                                            :
                                            <div></div>
                                        }

                                    </div>

                                    <div id="CategoryEditor-title-spacer-03-div" style={{height: "10px"}}/>

                                    <div style={{display: "flex", alignItems: "left", width: '100%'}}>

                                        <div style={{width: "20px"}}></div>

                                        <ArgoSelectPortalTextDisplayType
                                            templateType={TEMPLATE.TYPE.CATEGORY}
                                            textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_RIGHT}
                                            fieldName="rightTitle.selected"
                                            fieldTextObjName="rightTitle"
                                            initTo={this.state.rightTitle.selected}
                                            hideOption={false}
                                            labelText={"Right Title"}
                                            metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_CAT_TITLE_BAR_RIGHT}, propsObj)}
                                        />

                                        {(this.state.rightTitle.selected === 'custom') ?
                                            <ArgoTextDisplayCustom
                                                templateType={TEMPLATE.TYPE.CATEGORY}
                                                textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.HEADER_RIGHT}
                                                fieldName="rightTitle.selected"
                                                fieldTextObjName="rightTitle"
                                                initToObj={this.state.rightTitle.metadataValueObj}
                                                showDisplay={false}
                                                showTitleEntry={true}
                                                paddingTop={"0px"}
                                                metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_CAT_TITLE_BAR_RIGHT}, propsObj)}
                                            />
                                            :
                                            <div></div>
                                        }

                                    </div>

                                </div>
                                : ""}

                        </div>

                        <div id="CategoryEditor-break-between-titles-main-page">&nbsp;</div>

                        <div style={{justifyContent: "space-between"}}>

                            <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                                {this.props.showCreateToggle && this.props.showCreateToggle()}

                                <ArgoTextEntryWithCustomOverride
                                    templateType={TEMPLATE.TYPE.CATEGORY}
                                    textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.NAME}
                                    fieldName="name"
                                    fieldTextObjName="nameDisplay"
                                    label="Category Name"
                                    labelCustom="Display (Name)"
                                    value={this.props.template.name}
                                    maxlength={255}
                                    errorText={this.state.nameErrorText}
                                    initTo={this.state.nameDisplay.selected}
                                    initToObj={this.state.nameDisplay.metadataValueObj}
                                    hideOnly={this.state.customCategoryObject.titlebar}
                                    propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_NAME}, propsObj)}
                                />

                                <div id="CategoryEditor-qualifier-container-div"
                                     style={{display: "flex", alignItems: "left", width: '100%'}}>

                                    <ArgoTextFieldHook
                                        fileName="CategoryEditor"
                                        fieldName="nameQualifier"
                                        label="Name Qualifier"
                                        value={this.props.template.nameQualifier}
                                        maxLength={255}
                                        multiline={true}
                                        onChange={this.onTextChange}
                                        width="96%"
                                        divContainerHeight="52px"
                                    />

                                    <div style={{width: "83px"}}>&nbsp;</div>

                                </div>

                                <div>&nbsp;</div>

                                <ArgoTextEntryWithCustomOverride
                                    templateType={TEMPLATE.TYPE.CATEGORY}
                                    textEntryType={TEMPLATE.TEXT_ENTRY_TYPE.CATEGORY.DESC}
                                    fieldName="description"
                                    fieldTextObjName="descDisplay"
                                    label="Description/Instructions"
                                    labelCustom="Display (Description)"
                                    value={this.props.template.description}
                                    maxlength={255}
                                    initTo={this.state.descDisplay.selected}
                                    initToObj={this.state.descDisplay.metadataValueObj}
                                    propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_DESC}, propsObj)}
                                />

                                {/*// ===========================================================================================================*/}
                                {/*// METADATA EDITOR */}
                                {/*// ===========================================================================================================*/}

                                {/*<div>&nbsp;</div>*/}

                                <div style={{width: "100%", paddingBottom: "40px"}}>
                                    <ArgoMetadataEditor
                                        entityType={metadataTemplateTypes.CategoryTemplate}
                                        template={this.props.template}
                                        // onChange={this.props.updateCategoryTemplate}
                                        onChangeCallback={this.metadataChanged}
                                        metadata={this.props.template.metadata}
                                        errorText={this.state.metadataErrorText}
                                        setMetadataErrorText={this.setMetadataErrorText}
                                    />
                                </div>

                                {/*// ===========================================================================================================*/}
                                {/*// ADD GROUP TO CATEGORY */}
                                {/*// ===========================================================================================================*/}

                                <ArgoRadioGroupHook
                                    fileName="CategoryEditor"
                                    fieldName="isThisAnExistingGroup"
                                    label="Is this an existing group?"
                                    value={this.state.isThisAnExistingGroup}
                                    onChange={this.onChangeIsThisAnExistingGroup}
                                    checked={true}
                                />

                                <ArgoSelectExisting
                                    mode={TEMPLATE.TYPE.GROUP}
                                    paddingRight="0px"
                                    onAddTemplate={this.pickGroup.bind(this)}
                                />

                                <div>&nbsp;</div>

                                {/*// ===========================================================================================================*/}
                                {/*// ASSIGNED GROUPS LIST */}
                                {/*// ===========================================================================================================*/}

                                <GroupViewerExpandable
                                    parentName="CategoryEditor"
                                    categoryTemplate={this.props.template}
                                    groupKeys={this.props.groupKeys}
                                    setStateCallback={this.setStateCallback}
                                    hidePortalPreview={true}
                                    questionTemplate={this.props.questionTemplates}
                                />

                                {/*// ===========================================================================================================*/}
                                {/*// PORTAL PREVIEW */}
                                {/*// ===========================================================================================================*/}

                                <div id="CategoryEditor-space-above-portal-preview"
                                     style={{height: "20px"}}>&nbsp;</div>

                                <ArgoPortalPreview
                                    calledBy="CategoryEditor"
                                    createMode={this.props.createMode}
                                    templateType={TEMPLATE.TYPE.CATEGORY}
                                    template={categoryTemplateClone}
                                />

                            </div>
                        </div>
                    </div>
                </div> : <div id="CategoryEditor-container-outer-div"/>
        );
    }

}

CategoryEditor.defaultProps = {
    createMode: false,
    title: "",
    scrollTo: "",
    width: "100%"
};

CategoryEditor.propTypes = {
    scrollTo: PropTypes.string,
    createMode: PropTypes.bool,
    title: PropTypes.string,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    cancel: PropTypes.func,
    save: PropTypes.func,
    showCreateToggle: PropTypes.func,
    createInitialTemplate: PropTypes.object,
    categoryExpanderEdit: PropTypes.bool,
    categoryExpanderSetStateCallback: PropTypes.func,
    width: PropTypes.string
};

function mapStateToProps(state, props) {

    // if (state.categoryEditor.groupKeys.length > 0) {
    //     if (state.groupTemplate.hasOwnProperty(state.categoryEditor.groupKeys[0])) {
    //
    //     }
    // }

    return {
        template: state.categoryEditor.template,
        templateOriginal: state.categoryTemplate[props.templateKey],
        categoryTemplate: state.categoryTemplate,
        groupKeys: state.categoryEditor.groupKeys,
        options: state.categoryEditor.options,
        filter: state.filter["defaultfilter"],
        groupTemplateList: state.groupTemplateList,
        groupTemplate: state.groupTemplate,
        questionTemplate: state.questionTemplate,
        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.CategoryTemplate],
        //templateFromKey: state.categoryTemplate[props.templateKey],
        groupTemplates: state.groupTemplate,
        //groupKeysFromKey: state.categoryTemplate[props.templateKey]?.groupKeys || [],
        questionTemplates: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGroupTemplate,
        getCategoryTemplate,
        initializeCategoryEditorCreate,
        initializeCategoryEditorUpdate,
        updateCategoryTemplate,
        clearCategoryEditor,
        createGroupTemplate,
        saveGroupTemplate,
        validateCategoryNameAndQualifierUnique,
        getQuestionTemplate,
        addCategoryGroup,
        removeCategoryGroup,
        saveQuestionTemplate,
        expanderClear
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEditor);
